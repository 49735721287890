.row .group-row {
  padding: 8px 8px;
  cursor: pointer;
  border-radius: 10px;
}

.row .group-row:hover {
  background-color: #165b95;
  color: #ffffff;
}

.row .group-row.selected {
  background-color: #165b95;
  color: #ffffff;
}

.row > .search-wrapper .search-form input {
  padding-right: 130px;
}

.row > .search-wrapper .audience-type-dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 6px;
  height: calc(100% - 12px);
  border-left: 1px solid #165b95;
  width: 100px;
  gap: 8px;
}

.row > .search-wrapper .dropdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 16px;
  top: 6px;
  height: calc(100% - 12px);
  border-left: 1px solid #165b95;
  width: 100px;
  gap: 8px;
}

.row > .search-wrapper .dropdown-toggle {
  border: none;
  border-radius: 30px;
  padding: 5px 8px;
  min-width: 65px;
  background-color: transparent;
}

.add-assignment-modal .modal-body {
  max-height: 60vh;
  min-height: 16vh;
  overflow-y: scroll;
}

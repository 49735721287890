.assign-audience-modal .modal-body .group-row {
  font-size: 16px;
  cursor: pointer;
  padding: 4px 24px;
  transition: all 0.15s ease-in;
  margin: 4px 0;
  border-radius: 8px;
}

.assign-audience-modal .modal-body > .row {
  max-height: 50vh;
  overflow-y: scroll;
}

.assign-audience-modal .modal-body > h6 {
  font-size: 20px;
  margin-bottom: 16px;
}

.assign-audience-modal .modal-body .group-row:hover {
  background-color: #165b95;
  color: #ffffff;
}

.assign-audience-modal .modal-body .group-row.selected {
  background-color: #165b95;
  color: #ffffff;
}

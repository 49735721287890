.tabs-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #165b95;
  border-radius: 20px;
  user-select: none;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.tab:hover,
.tab.selected {
  background: #165b95;
  color: #ffffff;
}

.ds-card.third-section .student-edit-form .form-group > label {
  color: #01324b;
  font-weight: 700;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  display: block;
  margin: 0 0 5px 0;
}

.ds-card.third-section .student-edit-form .form-group .assignee-card-wrapper {
  position: relative;
}

.ds-card.third-section .student-edit-form .form-group .assignee-card-wrapper button {
  position: absolute;
  right: -6px;
  top: -12px;
  z-index: 1;
  border: 1px solid #165b95;
}

.ds-card.third-section .student-edit-form .form-group > .assignee-row {
  font-size: 17px;
  font-weight: bold;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 4px 24px;
  transition: all 0.15s ease-in;
  margin: 4px 0;
  border-radius: 8px;
}

.ds-card.third-section .student-edit-form .form-group > .assignee-row:hover {
  background-color: #165b95;
  color: #ffffff;
}

.ds-card.third-section .student-edit-form .form-group > .assignee-row > .remove {
  color: #cb1e5c;
  cursor: pointer;
  font-size: 15px;
  font-weight: medium;
}

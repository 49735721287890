@media (min-width: 1px) and (max-width: 882px) {
    .register-section > .row {
        width: 80%;
        position: initial;
        margin: 0 auto;
    }

    .register-section .btn.btn-primary {
        background-size: contain;
    }
}

@media (min-width: 824px) and (max-width: 991px) {
    .register-section > .row {
        min-width: 600px;
        right: 120px;
    }
}

@media (min-width: 992px) {
    .sidebar-active .ds-dropdown-menu {
        padding-left: 0;
        width: 150px;
        display: none !important;
    }

    .sidebar-active .ds-dropdown:hover .ds-dropdown-menu {
        display: block !important;
    }

    .sidebar-active .side-nav .ds-dropdown-menu li a {
        padding: 10px 15px;
    }

    .sidebar-active .ds-dropdown:hover .icon:before {
        transform: rotate(180deg);
    }

    .register-section > .row {
        min-width: 600px;
        right: 120px;
    }
}

/* md */
@media (min-width: 1950px) and (max-width: 2500px) {
    .register {
        width: 900px;
    }

    .register-content .btn.btn-primary {
        font-size: 40px;
        padding: 75px 0px;
    }

    .register-content {
        width: 100%;
        max-width: unset;
    }

    .register-section .btn.btn-primary {
        background-size: contain;
    }
}

/* md */
@media (min-width: 2500px) and (max-width: 3800px) {
    .register {
        width: 1100px;
    }

    .register-content .btn.btn-primary {
        font-size: 50px;
        padding: 95px 0px;
    }

    .register-section .btn.btn-primary {
        background-size: contain;
    }
}

/* md */
@media (min-width: 3800px) and (max-width: 5800px) {
    .register {
        width: 1400px;
    }
}

/* md */
@media (min-width: 5800px) and (max-width: 8800px) {
    .register {
        width: 1700px;
    }
}

/* md */
@media (min-width: 1400px) and (max-width: 1750px) {
    .ds-sidebar {
        width: 230px;
    }

    .header {
        padding-left: 230px;
    }

    .main-content {
        padding-left: 265px;
    }

    .ds-card .box h2 {
        font-size: 26px;
    }

    .ds-card .box .icon {
        margin-right: 10px;
    }

    .ds-dropdown-menu {
        padding-left: 45px;
    }

    .course {
        margin-top: 30px;
    }

    .course-top .thumb {
        margin-top: 0;
        max-width: 100px;
    }

    .course-top .text {
        padding-left: 5px;
        padding-top: 20px;
        width: 100%;
    }

    .course-top .text h2 {
        font-size: 18px;
    }

    .course {
        padding-left: 15px;
        padding-right: 15px;
    }

    .assignment .btn.btn-primary {
        padding: 35px 38px;
        background-size: contain;
    }

    .assignment .text h4 {
        font-size: 16px;
        min-height: 40px;
    }

    .assignment ul {
        min-height: 100px;
    }

    .assignment .text h5 {
        min-height: 40px;
    }

    .assignment p {
        min-height: 85px;
    }

    .side-nav li a {
        padding-left: 25px;
    }

    .log-out {
        padding: 25px;
    }

    .sidebar-footer .thumb img {
        width: 50%;
    }

    .sidebar-active .log-out:hover .ds-text {
        top: 22px;
    }

    .lightpick__month-title-bar {
        padding: 40px 0;
    }

    .lightpick__month-title {
        font-size: 28px;
    }

    .lightpick__month-title > .lightpick__select-years {
        font-size: 28px;
    }

    .lightpick__next-action {
        right: -145px;
    }

    .lightpick__previous-action {
        left: -145px;
    }

    .compare-calendar-modal .modal-dialog {
        max-width: 650px;
    }

    .lightpick__day-of-the-week {
        padding: 20px 0 20px;
    }

    .lightpick__day {
        font-size: 14px;
        height: 50px;
        width: 50px;
        margin: 10px auto;
    }

    .assign-book-modal .modal-dialog {
        max-width: 80%;
        width: 100%;
    }

    .assign-book-modal .modal-header {
        padding: 16px 25px 45px;
    }

    .assign-book-modal .course {
        margin-top: 10px;
    }

    .student-edit-modal .course-top {
        display: flex;
        margin-bottom: 20px;
        min-height: 130px;
    }

    .student-edit-modal .course p {
        min-height: 90px;
    }

    .modal_bg .modal-title {
        font-size: 24px;
        margin-bottom: 0;
    }
}

/* md */
@media (min-width: 1200px) and (max-width: 1399px) {
    .ds-sidebar {
        width: 220px;
    }

    .header {
        padding-left: 220px;
    }

    .log-out {
        padding: 10px 35px;
        margin-bottom: 15px;
    }

    .ds-dropdown .icon {
        height: 45px;
    }

    .ds-dropdown-menu {
        padding-left: 45px;
    }

    .main-content {
        padding-left: 255px;
    }

    .side-nav li a {
        padding: 5px 15px;
        padding-left: 20px;
    }

    .side-nav li a::before {
        left: -5px;
    }

    .ds-card .box h2 {
        font-size: 22px;
    }

    .ds-card .box .icon {
        max-width: 70px;
        margin-right: 5px;
        margin-bottom: -21px;
    }

    .ds-card .box {
        padding: 8px;
        overflow: hidden;
    }

    .ds-card .box .box-border {
        padding: 10px 0;
    }

    .course-top .thumb {
        max-width: 80px;
        margin-top: 30px;
    }

    .course {
        margin-top: 30px;
    }

    .course .btn.btn-primary {
        padding: 35px 20px;
    }

    .course-top .text h2 {
        font-size: 18px;
    }

    .course-card .col-md-4,
    .assignment-content .col-md-3 {
        width: 50%;
    }

    .sidebar-footer .thumb img {
        width: 30%;
    }

    .course .button {
        text-align: center;
        margin: 0;
    }

    .course-top .text ul li {
        margin-bottom: 5px;
        font-size: 12px;
    }

    .course-top {
        margin-bottom: 10px;
        min-height: 160px;
    }

    .course-top .text {
        width: 100%;
        padding-top: 30px;
        padding-left: 11px;
    }

    .course p {
        max-width: 100%;
    }

    /* .side-nav li a {
          padding-left: 20px;
      } */
    .ds-dropdown .icon:before {
        width: 10px;
        right: 10px;
        background-size: contain;
    }

    .side-nav li a span {
        margin-right: 5px;
    }

    .ds-sidebar .logo {
        padding: 15px 25px;
        margin-bottom: 5px;
        text-align: center;
    }

    .sidebar-active .log-out:hover .ds-text {
        top: 8px;
    }

    .assign_card .button {
        margin: 0;
    }

    .custom_primary_btn {
        padding: 35px 58px;
    }

    .lightpick__month-title-bar {
        padding: 40px 0;
    }

    .lightpick__month-title {
        font-size: 28px;
    }

    .lightpick__month-title > .lightpick__select-years {
        font-size: 28px;
    }

    .lightpick__next-action {
        right: -145px;
    }

    .lightpick__previous-action {
        left: -145px;
    }

    .compare-calendar-modal .modal-dialog {
        max-width: 650px;
    }

    .lightpick__day-of-the-week {
        padding: 20px 0 20px;
    }

    .lightpick__day {
        font-size: 14px;
        height: 50px;
        width: 50px;
        margin: 10px auto;
    }

    .assign-book-modal .modal-dialog {
        max-width: 80%;
        width: 100%;
    }

    .assign-book-modal .modal-header {
        padding: 16px 25px 45px;
    }

    .assign-book-modal .course {
        margin-top: 10px;
    }

    .student-edit-modal .course-top {
        display: flex;
        margin-bottom: 20px;
        min-height: 130px;
    }

    .student-edit-modal .course p {
        min-height: 90px;
    }

    .modal_bg .modal-title {
        font-size: 24px;
        margin-bottom: 0;
    }
}

@media (max-width: 1399px) {
    .side-nav li a::before {
        top: -14px;
    }
}

@media (max-width: 991px) {
    body.sidebar-active .side-nav li a::before {
        top: -6px;
    }
}

/* md */
@media (min-width: 992px) and (max-width: 1199px) {
    .ds-sidebar {
        width: 220px;
    }

    .header {
        padding-left: 220px;
    }

    .main-content {
        padding-left: 255px;
    }

    /* .side-nav li a {
          padding-left: 20px;
      } */
    .side-nav li a::before {
        left: -5px;
    }

    .ds-card .box h2 {
        font-size: 22px;
    }

    .ds-card .box .icon {
        max-width: 40px;
        margin-right: 5px;
        margin-bottom: -30px;
    }

    .ds-card .box {
        padding: 8px;
        overflow: hidden;
    }

    .ds-card .box .box-border {
        padding: 10px 0;
        font-size: 13px;
    }

    .course {
        margin-top: 30px;
    }

    .course-top .thumb {
        max-width: 80px;
        margin-top: 50px;
    }

    .course .btn.btn-primary {
        padding: 35px 40px;
    }

    .course-top .text h2 {
        font-size: 18px;
    }

    .course-card .col-md-4,
    .assignment-content .col-md-3 {
        width: 50%;
    }

    .search-form {
        width: 270px;
    }

    .sidebar-footer .thumb img {
        width: 70%;
    }

    .course-top .text .icon {
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        line-height: 25px;
    }

    .course-top {
        min-height: 177px;
    }

    .course-top .thumb {
        max-width: 80px;
        margin-top: 30px;
    }

    .course-top .text {
        padding-top: 20px;
        padding-left: 10px;
        width: 100%;
    }

    .course p {
        min-height: 126px;
    }

    .course-top .text .icon img {
        height: 14px;
    }

    .side-nav li a {
        padding: 5px 15px;
        padding-left: 20px;
    }

    .ds-dropdown .icon {
        height: 45px;
    }

    .ds-dropdown .icon:before {
        width: 10px;
        right: 10px;
        background-size: contain;
    }

    .side-nav li a span {
        margin-right: 5px;
    }

    .sidebar-footer .thumb img {
        width: 40%;
    }

    .register-footer {
        padding-right: 60px;
    }

    .ds-dropdown-menu {
        padding-left: 45px;
    }

    .log-out {
        padding: 15px 35px;
    }

    .ds-sidebar .logo {
        padding: 20px;
        margin-bottom: 5px;
        text-align: center;
    }

    .sidebar-active .log-out:hover .ds-text {
        top: 12px;
    }

    .modal_bg .modal-title {
        font-size: 24px;
        margin-bottom: 0;
    }
}

/* 992-1199 */
/* lg */
/* @media (max-width: 1440px) {
	.analysis_box .ana_cont p
	{
		font-size: 10px;
    line-height: 10px;
	}
	.nav-tabs .nav-link
	{
		padding: 5px 20px;
		background-size: cover;
		font-size: 13px;

	}
	.kid_score_each h5
	{
		
		font-size: 12px;
	}
	.kid_score_each p
	{
		max-width: 25px;
		text-align: center;
		min-width: 33px;
		padding: 5px 5px;
		font-size: 10px;
	}
	.kid_score_each
	{
		margin-bottom: 5px;
	}
	.kid_card .btn.btn-primary {
		overflow: visible;
		padding: 29px 52px;
		background-size: cover;
	}

} */
@media (max-width: 1600px) {
    .button,
    .button1 {
        /* display: inline-block; */
        margin: 0;
    }

    .custom_primary_btn {
        padding: 35px 65px;
    }

    .student-edit-modal .modal-header {
        padding: 25px 20px 40px;
    }

    .student-edit-modal .modal-body {
        padding: 20px 25px;
    }

    .student-edit-top h4 {
        margin: 0 0 10px 0;
    }

    .student-edit-modal .form-group {
        margin-bottom: 10px;
    }

    form .form-group .form-control {
        font-size: 16px;
    }

    form .form-group textarea.form-control {
        height: 80px;
    }
}

/* md */
@media (max-width: 1199px) {
    .assign-book-modal .modal-dialog {
        max-width: 95%;
        width: 100%;
    }

    .assign-book-modal .modal-body {
        padding: 15px 15px;
    }

    .assign-book-modal .course {
        margin-top: 10px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 5px;
    }

    .assign-book-modal .course-top .thumb {
        max-width: 70px;
        margin-top: 30px;
    }

    .assign-book-modal .course-top .text {
        padding-top: 20px;
        padding-left: 10px;
    }

    .assign-book-modal .course-top .text h2 {
        font-size: 16px;
    }

    .assign-book-modal .course .btn.btn-primary {
        padding: 35px 25px;
    }

    .compare-calendar-modal .modal-dialog {
        max-width: 90%;
    }

    .responsive-calendar .day {
        padding: 15px 0;
    }

    .accordion-button {
        padding: 20px 15px;
    }

    .accordion-button span {
        font-size: 20px;
    }

    .accordion-button p {
        font-weight: 400;
        font-size: 14px;
    }

    .accordion-button {
        padding: 15px 15px;
        flex-direction: column;
        align-items: flex-start;
    }

    .accordion-button span {
        font-size: 18px;
        width: 100%;
        margin: 0 0 10px 0;
    }

    .filter-modal-sec .modal-dialog {
        max-width: 95%;
        width: 100%;
    }

    .Exercise-modal .modal-dialog {
        max-width: 95%;
        width: 100%;
    }

    .custom_primary_btn {
        padding: 35px 75px;
    }

    .register-content .button-hover {
        width: 115%;
    }

    .lightpick__month-title-bar {
        padding: 40px 0;
    }

    .lightpick__month-title {
        font-size: 28px;
    }

    .lightpick__month-title > .lightpick__select-years {
        font-size: 28px;
    }

    .lightpick__next-action {
        right: -145px;
    }

    .lightpick__previous-action {
        left: -145px;
    }

    .compare-calendar-modal .modal-dialog {
        max-width: 650px;
    }

    .lightpick__day-of-the-week {
        padding: 20px 0 20px;
    }

    .lightpick__day {
        font-size: 14px;
        height: 50px;
        width: 50px;
        margin: 10px auto;
    }

    .assign-book-modal .modal-dialog {
        max-width: 80%;
        width: 100%;
    }

    .assign-book-modal .modal-header {
        padding: 16px 25px 45px;
    }

    .assign-book-modal .course {
        margin-top: 10px;
    }

    .student-edit-modal .course-top {
        display: flex;
        margin-bottom: 20px;
        min-height: 130px;
    }

    .student-edit-modal .course p {
        min-height: 90px;
    }

    .modal_bg .modal-title {
        font-size: 24px;
        margin-bottom: 0;
    }
}

@media (max-width: 991px) {
    .ds-sidebar,
    .sidebar-active .ds-sidebar {
        width: 220px;
        margin-left: -220px;
    }

    .sidebar-active .ds-sidebar {
        margin-left: 0;
    }

    .logo-lg {
        display: none;
    }

    .logo-sm {
        display: block;
    }

    .sidebar-active .header {
        margin-right: -220px;
        margin-left: 220px;
        padding-left: 0;
    }

    .sidebar-active .main-content {
        padding-left: 250px;
        margin-right: -220px;
    }

    .sidebar-active .sidebar-footer .thumb {
        opacity: 1;
    }

    .sidebar-active .ds-text {
        visibility: visible;
        display: inline-block;
    }

    .sidebar-active .ds-dropdown-menu {
        position: static;
        padding: 0 0 0 45px;
    }

    .header {
        padding-left: 0;
    }

    .search-form {
        width: 190px;
    }

    .main-content {
        padding-left: 30px;
    }

    .course-card .col-md-4,
    .assignment-card .col-md-3 {
        width: 50%;
    }

    .course-top .text h2 {
        font-size: 20px;
    }

    .sidebar-footer .thumb img {
        width: 30%;
    }

    .side-nav li a {
        padding: 5px 15px;
        padding-left: 25px;
    }

    .ds-sidebar .logo {
        padding: 20px 15px;
        margin-bottom: 5px;
    }

    .ds-dropdown .icon {
        height: 42px;
    }

    .ds-dropdown .icon::before {
        width: 10px;
        background-size: contain;
    }

    .ds-sidebar .logo {
        text-align: center;
    }

    .ds-sidebar .logo img {
        max-width: 140px;
    }

    .sidebar-active .logo-sm {
        display: none;
    }

    .ds-sidebar .logo-lg,
    .sidebar-active .logo-lg {
        display: block;
        visibility: visible;
    }

    .side-nav {
        padding-bottom: 0;
    }

    .side-nav li a:before {
        height: 60px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .sidebar-active .sidebar-footer .thumb {
        display: block;
    }

    .log-out {
        padding: 20px 25px;
        margin-bottom: 20px;
    }

    .register-footer {
        display: block;
        text-align: center;
        padding-right: 0;
    }

    .register-content .btn.btn-primary {
        margin: 0;
    }

    .register-footer ul {
        justify-content: center;
    }

    .sidebar-active .ds-text {
        position: initial;
        padding: 0;
        width: initial;
        height: initial;
        line-height: initial;
        background: transparent;
    }

    .sidebar-active a .ds-text:hover {
        background-color: transparent;
    }

    .sidebar-active .side-nav li a {
        padding-left: 20px;
    }

    .sidebar-active .ds-dropdown:hover .icon::before {
        transform: initial;
    }

    .student-edit-modal .modal-dialog {
        max-width: 90%;
        width: 100%;
    }

    .student-edit-modal .modal-body {
        padding: 25px 35px 20px;
    }

    form .form-group .form-control {
        font-size: 16px;
        padding: 0 0 0 25px;
    }

    .student-edit-modal .modal-header {
        padding: 30px 30px;
        background-position: bottom center;
    }

    .student-edit-modal .modal-title {
        font-size: 24px;
    }

    .btn-close {
        width: 30px;
        height: 30px;
    }

    .student-edit-modal .modal-body {
        padding: 15px 30px;
    }

    .student-edit-top h4 {
        font-size: 16px;
        margin: 0 0 20px 0;
    }

    .image-holder-inner {
        max-width: 115px;
    }

    .image-holder-inner .kid_card-edit {
        top: -5px;
        right: -10px;
    }

    .add-photo-modal .modal-footer {
        padding: 0 25px 30px;
    }

    .zoom-in {
        width: 100%;
        text-align: center;
        margin: 0 0 20px 0;
    }

    .add-photo-modal .modal-footer .media {
        justify-content: center;
        width: 100%;
    }

    .active_btn {
        font-size: 16px;
        padding: 5px 15px;
    }

    .complete_btn {
        font-size: 16px;
        padding: 5px 15px;
    }
}

/* 991 */

/* sm */
@media (min-width: 768px) and (max-width: 991px) {
    .ds-card .box h2 {
        font-size: 20px;
    }

    .ds-card .box .icon {
        max-width: 40px;
        margin-right: 5px;
    }

    .box-border {
        padding: 8px 0;
    }

    .ds-card .box {
        font-size: 13px;
        padding: 6px;
        overflow: hidden;
    }

    .course {
        margin-top: 30px;
        padding: 15px;
    }

    .course-top .thumb {
        margin-top: 30px;
    }

    .course-top {
        display: flex;
        margin-bottom: 30px;
        min-height: auto;
        align-items: center;
    }

    .course-top .thumb {
        margin-top: 0;
    }

    .course-top .text {
        flex: 1;
        padding-top: 0;
        padding-left: 15px;
        width: 100%;
    }

    .course p {
        max-width: 100%;
        min-height: auto;
        margin: 0;
    }
}

/* 768-991 */

/* sm */
@media (max-width: 767px) {
    /* .register-footer {
          position: static;
      } */
    .ds-sidebar .logo {
        margin-bottom: 0;
    }

    .register-section {
        /* height: 100%; */
        right: unset;
        width: 100%;
        min-width: unset;
    }

    .register-content {
        padding: 50px;
    }

    .register-footer {
        margin-top: 80px;
        display: block;
        text-align: center;
        padding: 15px;
    }

    .register-footer ul {
        justify-content: center;
    }

    .ds-card .box {
        margin-top: 20px;
    }

    .search-form {
        width: 140px;
    }

    .user-option > ul > li {
        margin-right: 15px;
    }

    .ds-card .box h2 {
        font-size: 26px;
    }

    .page-title {
        margin-bottom: 10px;
    }

    .main-content {
        padding: 120px 20px 20px;
    }

    .course .btn.btn-primary {
        padding: 35px 30px;
        background-size: contain;
    }

    .course-card .col-md-4,
    .assignment-card .col-md-3 {
        width: 100%;
    }

    .course-top .text {
        width: 100%;
    }

    .course p {
        max-width: 100%;
    }

    .course-top .thumb {
        margin-top: 0;
    }

    .course {
        margin-top: 30px;
    }

    .register-footer p {
        margin-bottom: 5px;
    }

    form .form-group .form-control {
        font-size: 14px;
        height: 41px;
        padding: 0 0 0 18px;
    }

    form .form-group .label-text {
        font-size: 14px;
        margin: 0 0 4px 0;
    }

    .down-img {
        right: 20px;
    }

    .down-img img {
        height: 10px;
    }

    .back-btn {
        left: 20px;
        top: 15px;
    }

    .btn-submit,
    .btn-cancel {
        font-size: 18px;
        max-width: 165px;
        height: 50px;
    }

    .student-edit-modal .modal-body {
        padding: 15px 15px;
    }

    .responsive-calendar .controls h4 {
        font-size: 24px;
    }

    .responsive-calendar .controls {
        margin: 0 0 10px 0;
        padding: 30px 0;
    }

    .responsive-calendar .day {
        font-size: 14px;
        padding: 10px 0;
    }

    .responsive-calendar .day a {
        font-size: 14px;
        width: 40px;
        height: 40px;
    }

    .compare-calendar-modal .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }

    .lightpick__days-of-the-week {
        padding: 0 15px;
    }

    .lightpick__days {
        padding: 0 10px;
    }

    .compare-calendar-modal .modal-footer {
        border: none;
        padding: 10px 10px;
    }

    .assign_card .card-body {
        padding: 15px !important;
    }

    .assign_title {
        font-size: 20px;
    }

    .assign_text {
        font-size: 16px;
        line-height: 24px;
    }

    .assign_card .button,
    .assign_card .button1 {
        margin: 0;
    }

    .register-content .btn.btn-primary {
        padding: 32px 0px;
    }
}

/* 767 */

/* xs Portrait */
@media (max-width: 575px) {
    .title {
        display: block;
    }

    .ds-footer {
        display: block;
        text-align: center;
    }

    .ds-footer ul {
        justify-content: center;
        margin-top: 10px;
    }

    .search-form {
        display: none;
    }

    .ds-sidebar {
        overflow-y: scroll;
    }

    .page-title h1 {
        font-size: 28px;
    }

    .header-cpntent::before {
        top: 3px;
    }

    .side-nav {
        margin-bottom: 0;
    }

    .register-content .logo {
        text-align: center;
    }

    .register-content .logo img {
        max-width: 200px;
    }

    .notification .dropdown-menu {
        min-width: 290px;
    }

    .notification .nt-top h1 {
        font-size: 20px;
    }

    .sidebar-footer .thumb {
        display: none !important;
    }

    .student-edit-modal .modal-dialog {
        margin: 0 auto;
    }

    .student-edit-modal .modal-title {
        font-size: 20px;
    }

    .btn-close {
        width: 20px;
        height: 20px;
    }

    .student-edit-modal .modal-header {
        padding: 25px 15px;
    }

    .student-edit-modal .modal-body {
        padding: 15px;
    }

    .image-holder-inner {
        max-width: 90px;
    }

    .image-holder-inner {
        border: 2px solid #0e2d54;
        padding: 3px;
    }

    .image-holder-inner .kid_card-edit {
        top: -1px;
        right: -5px;
        width: 28px;
        height: 28px;
    }

    .image-holder-inner .kid_card-edit img {
        height: 15px;
    }

    .student-edit-top h4 {
        font-size: 14px;
        margin: 0 0 15px 0;
    }

    .form-group {
        position: relative;
        margin-bottom: 15px;
    }

    form .form-group .form-control {
        background: none;
        border: 2px solid #38a6de;
        border-radius: 16px;
        font-size: 14px;
        height: 50px;
        padding: 0 0 0 15px;
    }

    .btn-submit {
        font-size: 16px;
        max-width: 120px;
        height: 35px;
    }

    .btn-cancel {
        font-size: 16px;
        max-width: 120px;
        height: 35px;
        margin-right: 10px;
    }

    .assign-book-modal .search-form {
        display: block !important;
    }

    .sec_body {
        padding: 15px;
    }

    .assign_title {
        font-size: 16px;
    }

    .assign_text {
        font-size: 16px;
        line-height: 22px;
        color: #52525b;
        margin: 10px 0 0 0;
    }

    .custom_primary_btn {
        padding: 30px 75px;
        font-size: 14px;
    }

    .responsive-calendar .controls h4 {
        font-size: 20px;
        margin: 0 15px;
    }

    .calendar-down {
        margin-left: 10px;
    }

    .responsive-calendar .controls {
        padding: 25px 0;
    }

    .responsive-calendar .day {
        font-size: 12px;
        padding: 10px 0;
    }

    .responsive-calendar .day a {
        font-size: 12px;
        width: 30px;
        height: 30px;
    }

    .accordion-button span {
        font-size: 14px;
        width: 100%;
        margin: 0 0 10px 0;
    }

    .course-top .thumb {
        text-align: center;
    }

    .course .button {
        margin: 0;
    }

    .filter-modal-sec .modal_bg {
        padding: 20px;
    }

    .filter-modal-sec .modal-body {
        padding: 20px;
    }

    .filter-modal-sec .modal-footer {
        padding: 0 20px 20px;
    }

    .search-form.onpage {
        display: block;
    }

    .see-all-btn {
        font-size: 16px;
        line-height: 30px;
        padding: 5px 15px;
        border-radius: 13px;
    }

    .library-media {
        flex-direction: column;
        gap: 15px;
    }

    .title_libary-btn .button a {
        width: 100%;
    }

    .Exercise-modal .modal_bg {
        padding: 15px;
    }

    .Exercise-modal .modal-body {
        padding: 20px;
    }

    .fiter_text {
        font-size: 14px;
    }

    .Exercise-modal .image-holder {
        text-align: center;
        margin: 15px 0 0 0;
    }

    .Exercise-modal .modal-footer {
        border: none;
        padding: 0 20px 20px;
    }

    .lightpick__days-of-the-week {
        padding: 0 10px;
    }

    .lightpick__days {
        padding: 0 10px;
    }

    .lightpick__day-of-the-week {
        padding: 30px 0;
        font-size: 12px;
    }

    .lightpick__day {
        font-size: 12px;
        height: 40px;
        width: 40px;
        margin: 10px auto;
    }

    .lightpick__month-title {
        font-size: 24px;
    }

    .lightpick__month-title > .lightpick__select-years {
        font-size: 24px;
    }

    .lightpick__previous-action {
        left: -130px;
    }

    .lightpick__next-action {
        right: -130px;
    }

    .add_modal_btn {
        font-size: 14px;
        padding: 5px 10px;
    }

    .pro_text {
        font-size: 18px;
    }

    .register-content .button-hover {
        width: 108%;
    }
}

/* 575 */

/* xs Portrait */
@media (max-width: 475px) {
    .register-content {
        padding: 50px 20px;
    }

    .user-option > ul > li {
        margin-right: 5px;
    }

    .header-cpntent {
        padding-right: 15px;
        padding-left: 15px;
    }

    .header-cpntent::before {
        left: -10px;
    }

    .ds-card .box .icon {
        max-width: 60px;
        margin-bottom: -29px;
    }

    .ds-card .box h2 {
        font-size: 22px;
    }

    .ds-card .box {
        padding: 10px;
        overflow: hidden;
    }

    .ds-card .box .box-border {
        padding: 8px 0;
    }

    .assignment-content {
        padding-left: 0;
        margin-left: 0;
        border-left: 0;
    }

    .assignment-card {
        padding: 15px;
    }

    .title h1 {
        font-size: 22px;
    }

    .assignment-content::before {
        display: none;
    }

    .assignment .btn.btn-primary {
        padding: 26px 40px;
        background-size: contain;
    }

    .assignment ul {
        min-height: initial;
    }

    .user-dropdown .dropdown-toggle img {
        margin-right: 5px;
    }

    .notification .icon {
        width: 30px;
        height: 30px;
        line-height: 30px;
    }

    .notification .icon::before {
        top: 3px;
        right: 5px;
    }

    .toggler {
        margin-right: 0px;
        width: 30px;
        height: 30px;
        max-width: 30px;
        line-height: 27px;
        position: relative;
        z-index: 99999;
    }

    .course-top {
        display: block;
    }

    .course-top .text {
        padding-top: 15px;
        padding-left: 0;
    }

    .register-content input {
        font-size: 16px;
    }

    .register-content .btn.btn-primary {
        font-size: 18px;
    }

    .notification .icon img {
        width: 14px;
        margin-top: -5px;
    }

    .languages .dropdown-toggle {
        min-width: 45px;
        padding: 5px 8px;
        margin-left: 10px;
    }

    .user-dropdown .dropdown-toggle img {
        min-width: 25px;
    }

    .header-cpntent .left-content {
        width: 40px;
        position: relative;
        z-index: 999999;
    }

    .user-dropdown .dropdown-toggle {
        padding-right: 5px;
    }

    .ds-sidebar {
        overflow-y: scroll;
    }

    .sidebar-content {
        min-height: 400px;
    }

    .lightpick__month-title-bar {
        padding: 30px 0;
    }
}

/* 475 */
@media (max-width: 374px) {
    .assign-book-modal .button,
    .assign-book-modal .button1 {
        margin: 0px 0px;
    }

    .assign-book-modal .course .btn.btn-primary {
        padding: 35px 15px;
    }

    .modal_btn {
        padding: 15px 10px;
        font-size: 12px;
    }

    .pro_text {
        font-size: 18px;
    }

    #home-tab-pane .modal_btn {
        padding: 7px 17px;
    }

    .assign_card .card-body {
        padding: 15px !important;
    }

    .assign_card .card-body .button,
    .assign_card .card-body .button1 {
        /* display: inline-block; */
        margin: 0;
    }

    .custom_primary_btn {
        padding: 33px 10px;
        font-size: 12px;
        width: 95%;
        background-size: cover;
    }

    .lightpick__day {
        height: 30px;
        width: 30px;
    }

    .ds-card .d-flex {
        display: block !important;
    }

    .register-content .btn.btn-primary {
        padding: 26px 0px;
    }
}

/* @media (max-width:992px) {
	.active_btn {
		font-size: 15px;
	}
	.complete_btn {
		font-size: 15px;
	}
	.stud_title {
		font-size: 20px;
	}
}

@media (max-width:576px) {
	.small_gray_text {
		text-align: end;
	}
	.recent_text{
		font-size: 13px;
	}

	.add_modal_btn {
		font-size: 14px;
	}
	.download_modal_btn {
		font-size: 14px;
		margin-bottom: 15px;
	}
	.techer_blue-title {
		font-size: 20px;
	}
	.techer_gray-title {
		font-size: 14px;
	}
	.assign_card-body {
		padding: 20px;
	}
}

@media (max-width:695px) {
	.active_btn {
		margin-bottom: 15px;
	}
}

@media (max-width:340px) {
	.pro_text {
		font-size: 20px;
	}
	.title_current h1 {
		font-size: 20px;
	}
	
}
@media (max-width:420px) {
	.assign_title {
		font-size: 14px;
	}
	.assign_text{
		font-size: 14px;
		line-height: 22px;
	}
	.assign_btn {
		font-size: 11px;
	}
	.book_small_content {
		font-size: 13px;
	}
	.book_big_content {
		font-size: 20px;
	}
	.assign_content h3{
		font-size: 20px;
	}
} */

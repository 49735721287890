/*
Template Name:
Template URL:
Author:
Author URI : http:///
Version: 1.0
Description: HTML5 Responsive Template.
Tag: HTML5, CSS3, jQuery, Responsive Template.
*/

/* Table of Contents
==================================================
# Global
# header


==================================================

*/
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;800&family=Fredoka+One&family=Montserrat:wght@400;700&family=Poppins:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");

/* MODULES */
@import "../modules/progressReportClass/partials/AddToGroupModal/styles.css";
@import "../modules/createAssignment/partials/ThirdStepSection/styles.css";
@import "../modules/createAssignment/partials/ThirdStepSection/SelectAudienceModal/styles.css";

/* COMPONENTS */
@import "../components/Tabs/styles.css";

html,
body {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #52525b;
    display: block;
    overflow-x: hidden;
    background-color: #f8f8f8;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    color: #000;
    text-decoration: none;
}

a:hover {
    color: #5ce1e6;
}

img {
    max-width: 100%;
}

section {
    padding: 100px 0;
    position: relative;
}

h2 {
    font-size: 32px;
    line-height: 150%;
    margin-bottom: 0;
    font-family: "Fredoka One", cursive;
    color: #0e2d54;
}

h3 {
    color: #0E2D54;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
    font-style: normal;
    font-weight: 700;
}

h5 {
    color: #0E2D54;
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    font-weight: 700;
}

p {
    margin: 0;
    color: #52525B;
    font-size: 14px;
    line-height: 24px;
}

.color_red_1 {
    color: #9c0035;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.spinned {
    animation: spin 1s linear infinite;
}


.btn.btn-primary {
    padding: 37px 40px;
    background-color: transparent;
    background-image: url("src/assets/images/btn-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    font-family: "Baloo 2", cursive;
    width: 100%;
    height: auto;
    position: relative;
    max-width: 260px;
}

.btn img {
    margin-left: 5px;
}

.btn:last-child {
    margin-right: 0;
}

.btn:hover,
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
    background-color: transparent;
}

.title {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
}

.title h1 {
    font-size: 24px;
    color: #000;
    font-weight: 800;
    margin-bottom: 15px;
    font-family: "Baloo 2", cursive;
}

.title .button a {
    color: #fff;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    background-color: #165b95;
    font-family: "Baloo 2", cursive;
    opacity: 1;
    transition: opacity .3s;
}

.title .button a:hover {
    opacity: .9;
}

body.sidebar-active .side-nav li a:before {
    top: -14px;
}

button:focus,
input:focus,
.form-control:focus {
    outline: none;
    box-shadow: none;
}

/******** Custom Scrollbar  ********/
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #123960;
}

/******** Custom Scrollbar end here  ********/

.form-group-inline {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 34px;
}

.schoolAddForm {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-gap: 30px;
    padding-top: 30px;
}

.addImgWrapper {
    text-align: center;
}

.addImgWrapper .addImageBtn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 195px;
    border: 2px dashed #AED5F1;
    border-radius: 16px;
    margin-bottom: 25px;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .3s;
}

.addImgWrapper .addImageBtn.notEmpty {
    border: none;
}

.addImgWrapper .btn-close {
    display: flex;
    align-items: center;
    width: max-content;
    height: max-content;
    margin: auto;
}

.addImgWrapper .btn-close p {
    margin-right: 5px;
    margin-bottom: 0;
    color: #0e2d54;
}

.addImgWrapper .addImageBtn img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 16px;
}

.addImgWrapper .addImageBtn p {
    margin: 0;
    padding: 15px;
}

.addImgWrapper .addImageBtn:hover {
    background-color: rgba(174, 213, 241, 0.26);
}

.addImgWrapper .addImageLabel {
    color: #0E2D54;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.schoolAddForm .submitRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0;
}

.schoolAddForm form .form-group input.form-control::placeholder {
    color: #9ab3c2;
}

.schoolAddForm form .form-group textarea.form-control::placeholder {
    color: #9ab3c2;
}

.schoolAddForm form .form-group textarea {
    padding-left: 26px !important;
}

@media (max-width: 992px) {
    .form-group-inline {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 0;
    }

    .schoolAddForm {
        padding-top: 0;
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }

    .addImgWrapper .addImageBtn {
        width: 100%;
        height: 120px;
        margin-bottom: 10px;
    }

    .addImgWrapper .addImageLabel {
        font-size: 16px;
    }
}

/*ds-sidebar*/

.ds-sidebar {
    position: fixed;
    left: 0;
    width: 335px;
    height: 100vh;
    z-index: 9999;
    background-color: #0e2d54;
    background-image: url("src/assets/images/sidebar-bg.png");
}

.sidebar-content {
    height: 100vh;
    position: relative;
}

.ds-sidebar .logo {
    padding: 30px;
    margin-bottom: 20px;
}

.side-nav {
    margin-bottom: 35px;
    padding-bottom: 80px;
}

.side-nav li a {
    color: #fff;
    font-weight: 500;
    padding: 15px 15px;
    padding-left: 35px;
    display: flex;
    align-items: center;
    margin-bottom: 1px;
    position: relative;
    border-radius: 4px 0px 0px 4px;
    z-index: 9;
}

.side-nav li a:before {
    position: absolute;
    content: "";
    top: -5px;
    left: 0;
    width: 19px;
    height: 78px;
    opacity: 0;
    background-image: url("src/assets/images/sp1.png");
}

.side-nav li a span.ds-icon {
    min-width: 30px;
    width: 35px;
    height: 35px;
    line-height: 33px;
    border-radius: 100%;
    text-align: center;
    margin-right: 10px;
    background-color: #fff;
    display: inline-block;
}

.side-nav li a span.ds-icon img {
    -webkit-filter: invert(30%) grayscale(100%) brightness(40%) contrast(4);
    filter: invert(30%) grayscale(100%) brightness(40%) contrast(4);
    width: 18px;
    height: 18px;
}

.side-nav li a.active span.ds-icon,
.side-nav li:hover a span.ds-icon {
    background: linear-gradient(358.51deg, #32a3dd 17.07%, #32a3dd 58.94%, #1180bf 97.37%);
}

.side-nav li a.active span.ds-icon img,
.side-nav li:hover a span.ds-icon img {
    filter: initial;
}

.side-nav li > a.active,
.side-nav li:hover > a,
.ds-dropdown-menu li.active,
.ds-dropdown-menu li:hover {
    color: #fff;
    background: linear-gradient(270.01deg, rgba(71, 199, 239, 0.1) 0.01%, rgba(27, 172, 224, 0) 44.03%);
}

.side-nav li a.active:before,
.side-nav li:hover a:before {
    opacity: 1;
}

.ds-dropdown {
    position: relative;
}

.ds-dropdown .icon {
    width: 100%;
    height: 65px;
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    cursor: pointer;
}

.ds-dropdown .icon:before {
    content: "";
    width: 15px;
    height: 15px;
    top: 50%;
    right: 15px;
    margin-top: -7px;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("src/assets/fonts/icon1.svg");
}

.ds-dropdown.active .icon:before {
    transform: rotate(180deg);
}

.ds-dropdown-menu {
    display: none;
    padding-left: 45px;
}

.sidebar-active .ds-dropdown-menu {
    position: absolute;
    left: 100%;
    /* top: 0;
      padding: 15px;
      min-width: 150px; */
    background-color: #0e2d54;
    top: 100%;
    min-width: 140px;
}

/* .sidebar-active .ds-dropdown-menu li a {
    padding-left: 0;
    display: block;
} */

.ds-dropdown-menu li img {
    margin-right: 10px;
}

.icon-h {
    display: none;
}

.ds-dropdown-menu .active > a .icon-h,
.ds-dropdown-menu li:hover > a .icon-h {
    display: block;
}

.ds-dropdown-menu .active > a .icon-n,
.ds-dropdown-menu li:hover > a .icon-n {
    display: none;
}

.ds-dropdown li a:before {
    display: none;
}

.ds-dropdown li a {
    color: #fff;
    cursor: pointer;
    display: flex;
    padding: 10px 15px;
    margin-bottom: 1px;
}

.ds-dropdown li a:hover,
.ds-dropdown li a.active {
    color: #32a3dd;
}

.log-out {
    font-size: 16px;
    padding: 25px 0 25px 25px;
    border-bottom: 1px dashed rgb(242 242 242 / 20%);
}

.log-out button {
    appearance: none;
    background-color: transparent;
    border: none;
    color: #fff;
    font-weight: 500;
    display: block;
}

.sidebar-active .log-out:hover .ds-text {
    display: block;
    top: 32px;
}

.log-out button span.ds-icon {
    min-width: 30px;
    width: 35px;
    height: 35px;
    line-height: 33px;
    border-radius: 100%;
    text-align: center;
    margin-right: 5px;
    background-color: #fff;
    display: inline-block;
}

.sidebar-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.sidebar-footer .thumb {
    text-align: center;
    padding: 0 20px;
    margin-top: 35px;
}

.sidebar-footer .thumb img {
    width: 100%;
    height: auto;
    max-width: 100px;
}

/*main-content*/

.main-content {
    padding: 120px 30px 30px;
    position: relative;
    padding-left: 365px;
}

.sidebar-active .ds-sidebar {
    margin-left: 0;
    width: 120px;
}

.sidebar-active .header {
    padding-left: 120px;
}

.sidebar-active .ds-text {
    /* visibility: hidden; */
    position: absolute;
    left: 100%;
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    width: 150px;
    display: none;
    background-color: #0e2d54;
}

.sidebar-active li:hover .ds-text {
    display: block;
}

.sidebar-active a .ds-text:hover {
    background-color: #32a3dd;
}

.sidebar-active .side-nav li a {
    padding: 5px 15px;
    padding-left: 35px;
}

.sidebar-active .ds-dropdown .icon {
    height: 45px;
}

.logo-sm {
    display: none;
    visibility: hidden;
}

.sidebar-active .logo-sm {
    display: inline-block;
    visibility: visible;
}

.sidebar-active .logo-lg {
    display: none;
    visibility: hidden;
}

.sidebar-active .sidebar-footer .thumb {
    display: none;
}

.sidebar-active .main-content {
    padding-left: 145px;
}

/*header*/

.header {
    margin-bottom: 50px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding-left: 335px;
    font-family: "Poppins", sans-serif;
    box-shadow: 0 0 10px rgb(0 0 0 / 5%);
}

.header-cpntent {
    align-items: center;
    width: 100%;
    display: flex;
    padding: 20px 30px;
    position: relative;
    justify-content: space-between;
    background-color: #fff;
}

.header-cpntent:before {
    position: absolute;
    content: "";
    top: 7px;
    left: 0;
    width: 19px;
    height: 78px;
    background-image: url("src/assets/images/sp1.png");
}

.header-cpntent .left-content {
    display: flex;
    align-items: center;
}

.toggler {
    width: 38px;
    height: 38px;
    line-height: 35px;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    margin-right: 15px;
    background-color: #0e2d54;
    flex-shrink: 0;
}

.search-form {
    width: 370px;
}

.search-form form,
.search-form .form {
    position: relative;
    width: 100%;
}

.search-form form input,
.search-form .form input {
    border: 2px solid #e3e2e2;
    border-radius: 30px;
    height: 50px;
    width: 100%;
    padding: 6px 20px;
    padding-left: 50px;
}

.search-form form input::-webkit-input-placeholder,
.search-form .form input::-webkit-input-placeholder {
    color: #e3e2e2;
}

.search-form form input::-moz-placeholder,
.search-form .form input::-moz-placeholder {
    color: #e3e2e2;
}

.search-form form input:-ms-input-placeholder,
.search-form .form input:-ms-input-placeholder {
    color: #e3e2e2;
}

.search-form form input:-moz-placeholder,
.search-form .form input:-moz-placeholder {
    color: #e3e2e2;
}

.search-form form button, .search-form .form button {
    position: absolute;
    top: 13px;
    left: 10px;
    background-color: transparent;
    border: 0;
}

.search-form.onpage .button {
    position: absolute;
    top: 13px;
    left: 18px;
}

.row > .search-wrapper .dropdown-toggle {
    position: relative;
    top: inherit;
    left: inherit;
}

.row > .search-wrapper .dropdown-wrapper {
    align-items: flex-start;
}

.user-option > ul {
    display: flex;
    align-items: center;
}

.user-option > ul > li {
    margin-right: 25px;
}

.user-option > ul > li:last-child {
    margin-right: 0;
}

.languages .dropdown-toggle {
    border: 1px solid #e3e2e2;
    border-radius: 30px;
    padding: 5px 8px;
    min-width: 65px;
    background-color: transparent;
}

.notification .nt-top {
    display: flex;
    padding: 30px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
    background-image: url("src/assets/images/bg-top.png");
    background-repeat: no-repeat;
}

.notification .nt-top h1 {
    font-size: 24px;
    color: #0c3043;
    margin-bottom: 0;
    font-weight: 800;
    font-family: "Baloo 2", cursive;
}

.notification .nt-top a {
    color: #000;
}

.notification .dropdown-menu {
    min-width: 380px;
    border: 0;
    padding: 0;
    overflow: hidden;
    border-radius: 20px;
    background-color: #f2f9ff;
}

.notification .dropdown-menu ul {
    padding: 15px;
}

.notification .dropdown-menu li {
    margin-bottom: 15px;
}

.notification .dropdown-menu li a {
    color: #000;
    display: flex;
    padding-left: 0;
    padding-right: 0;
    white-space: normal;
    align-items: center;
}

.notification .dropdown-menu li a span {
    min-width: 25px;
    margin-right: 5px;
}

.notification .dropdown-menu li a:hover {
    background-color: transparent;
}

.notification .dropdown-toggle::after {
    display: none;
}

.notification .icon {
    width: 38px;
    height: 38px;
    line-height: 35px;
    border-radius: 100%;
    text-align: center;
    position: relative;
    border: 1px solid #e3e2e2;
    border-radius: 100%;
    cursor: pointer;
    background-color: transparent;
}

.notification .icon:before {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    top: 6px;
    right: 9px;
    border-radius: 100%;
    background: linear-gradient(358.51deg, #32a3dd 17.07%, #32a3dd 58.94%, #1180bf 97.37%);
}

.user-dropdown .dropdown-toggle {
    padding: 0;
    border: 1px solid #e3e2e2;
    border-radius: 30px;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.user-dropdown .dropdown-toggle img {
    margin-right: 10px;
    min-width: 45px;
}

.user-dropdown .dropdown-toggle span {
    display: inline-block;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    font-size: 10px;
    color: #c9c9c9;
}

.user-dropdown .dropdown-toggle strong {
    display: block;
    font-weight: 500;
    color: #171717;
    font-size: 14px;
    line-height: 12px;
    margin-top: 3px;
}

/*ds-card*/

.ds-card {
    margin-bottom: 30px;
}

.page-title {
    color: #52525b;
    margin-bottom: 30px;
    font-family: "Montserrat", sans-serif;
}

.page-title h1 {
    font-size: 36px;
    color: #0e2d54;
    font-weight: 800;
    font-family: "Baloo 2", cursive;
}

.ds-card .box {
    color: #fff;
    padding: 15px;
    border-radius: 20px;
    font-family: "Montserrat", sans-serif;
    background-color: #FDE5E5;
}

.ds-card .box.box1 {
    background-color: #FBE4D6;
}

.ds-card .box.box2 {
    background-color: #E4F6E7;
}

.ds-card .box.box3 {
    background-color: #D6EAF8;
}

.ds-card .box.box4 {
    background-color: #f9e6b5;
}

.ds-card .box .icon {
    margin-right: 20px;
    margin-bottom: -20px;
}

.ds-card .box .box-border {
    display: flex;
    border: 1px dotted #fff;
    border-radius: 20px;
    align-items: center;
}

.ds-card .box h2 {
    font-size: 32px;
    margin-bottom: 0;
    font-family: "Fredoka One", cursive;
}

.ds-card .box p {
    margin-bottom: 0;
}

.ds-card .second-row {
    margin-top: 20px;
    /* Spacing between the first and second row */
}

.teacher-stats .box-border2 {
    display: flex;
    align-items: center;
    /* Vertically center items */
    justify-content: space-around;
    /* Horizontally align items */
}

.teacher-stats .box-border2 .icon {
    color: #0e2d54;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 0px;
    margin-bottom: 0px;
}

.ds-card .row.second-row {
    display: flex;
    justify-content: center;
    /* Centers the row horizontally */
}

.teacher-stats .box {
    text-align: center;
    /* Center text and values within each box */
}

/*chart-card*/

.chart-card {
    box-shadow: 0 0 10px rgb(0 0 0 / 18%);
    border-radius: 12px;
    overflow: hidden;
}

.chart-card .chart-thumb img {
    width: 100%;
}

/*course-card*/

.course {
    background-color: #f2f9ff;
    margin-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.course-top {
    display: flex;
    margin-bottom: 20px;
    min-height: 165px;
}

.course-top .thumb {
    margin-top: -50px;
}

.course-top .text {
    padding-top: 30px;
    padding-left: 15px;
}

.course-top .text .icon {
    width: 36px;
    height: 36px;
    display: inline-block;
    line-height: 36px;
    border-radius: 100%;
    text-align: center;
    background-color: #dfeffb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
}

.course-top .text h2 {
    font-weight: bold;
    font-size: 22px;
    font-family: "Montserrat", sans-serif;
}

.course-top .text h2 a {
    color: #0e2d54;
}

.course-top .text ul li {
    margin-bottom: 10px;
    display: flex;
}

.course-top .text ul li span {
    margin-right: 5px;
    min-width: 20px;
}

.course p {
    max-width: 370px;
    min-height: 105px;
    margin: 0;
}

.course .button {
    text-align: center;
}

/*assignments-page*/

.assignmentsTableWrapper {
    overflow-x: auto;
}

.assignments-page table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.assignments-page table > thead:after {
    content: "-";
    display: block;
    line-height: 16px;
    color: transparent;
}

.assignments-page table tr td:nth-child(1),
.assignments-page table tr td:nth-child(2) {
    width: 15%;
}

.assignments-page table tr td:nth-child(3),
.assignments-page table tr td:nth-child(4) {
    width: 20%;
}

.assignments-page table tr td:nth-child(5) {
    width: 30%;
}

.assignments-page table tr td:nth-child(5) > div {
    display: flex;
}

.assignments-page table thead th:nth-child(5) {
    text-align: center;
}

.assignments-page table thead th {
    color: #01324b;
    text-transform: capitalize;
}

.assignments-page table tbody tr > td,
.assignments-page table thead tr > th {
    padding: 8px;
}

.assignments-page table tbody tr {
    border-radius: 12px;
    transition: all 0.2s ease-in;
}

.assignments-page table tbody tr:hover {
    background-color: #165b95;
}

.assignments-page table tbody tr:hover > td {
    color: #f2f9ff;
}

.assignments-page table tbody tr {
    text-align: center;
}

.assignments-page table tbody tr.even {
    background-color: #e2f2ff;
}


.assignments-page table tbody tr:hover {
    background-color: #32a2dc;
    color: #fff;
}

.assignments-page table tbody tr td {
    color: #01324b;
}

.assignments-page table thead th,
.assignments-page table tbody tr td {
    text-align: center;
}

.assignments-page table thead th:first-child,
.assignments-page table tbody tr td:first-child {
    text-align: left;
}

.assignments-page table .actionsWrapper {
    width: max-content;
    margin-left: auto;
}


.assignments-page table thead th:last-child,
.assignments-page table tbody tr td:last-child {
    text-align: right;
}

.assignments-page .table-header {
    display: flex;
    justify-content: space-between;
}

.assignments-page .form-group select.form-control {
    font-weight: 700;
    font-size: 20px;
    color: #165b95;
    font-family: "Baloo 2", cursive !important;
    background: #ffffff;
    border: none;
    border: 2px solid #38a6de;
    border-radius: 20px;
    height: 43px;
    min-width: 350px;
    padding: 0 0 0 26px;
}

@media screen and (max-width: 650px) {
    .assignments-page .table-header {
        flex-direction: column;
    }

    .assignments-page .form-group select.form-control {
        min-width: 100%;
    }

    .assignmensPageHeader .btn {
        padding: 30px 50px;
    }
}

/*create-assignments-page*/

.create-assignment-page .form-group button.btn-edit {
    min-width: 160px;
    width: fit-content;
    height: auto;
    background-color: #165b95;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 35px;
    padding: 6px 30px;
    border-radius: 20px;
}

.create-assignment-page .second-step .row > .image-wrapper {
    position: relative;
    max-width: 250px;
}

.create-assignment-page .second-step .row > .image-wrapper img {
    border-radius: 12px;
}

.create-assignment-page .second-step .row > .image-wrapper > button {
    position: absolute;
    right: -6px;
    top: -12px;
    z-index: 1;
    border: 1px solid #165b95;
}

.create-assignment-page .second-step .row > .image-wrapper > .types-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
    position: absolute;
    bottom: 8px;
    left: 18px;
}

.create-assignment-page .second-step .row > .image-wrapper > .types-wrapper > .type-label {
    background-color: #165b95;
    border-radius: 16px;
    padding: 6px 10px;
    border: 1px solid #ffffff;
    min-width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
}

.create-assignment-page .second-step .chips-wrapper {
    display: flex;
    gap: 8px;
}

.create-assignment-page .add-assignment-modal .library-media {
    margin-bottom: 32px;
}

.create-assignment-page .add-assignment-modal .title_libary-btn {
    margin-bottom: 0;
}

.create-assignment-page .second-step .chips-wrapper > .chip {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    border: 1px solid #165b95;
    border-radius: 20px;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in;
}

.create-assignment-page .second-step .chips-wrapper > .chip:hover,
.create-assignment-page .second-step .chips-wrapper > .chip.selected {
    background: #165b95;
    color: #ffffff;
}

.create-assignment-page .form-group button.btn-edit:hover {
    color: #165b95 !important;
    background-color: #e2f3ff !important;
}

.create-assignment-page .form-group > input::placeholder,
.create-assignment-page .form-group > textarea::placeholder,
.create-assignment-page .react-datepicker-wrapper .react-datepicker__input-container > input::placeholder {
    color: #71717a !important;
}

.create-assignment-page .form-group > textarea {
    padding-left: 26px !important;
    color: #165b95 !important;
}

.create-assignment-page form > .row {
    justify-content: center;
}

.create-assignment-page form > .row > button {
    max-width: 250px;
}

.create-assignment-page .react-datepicker .react-datepicker__triangle {
    display: none;
}

.create-assignment-page .ds-card.sec_body .loaderWrapper #preloader {
    position: relative;
    height: auto;
}

.modal-body .usersList {
    padding-bottom: 60px;
}

/*assignment-card*/

.assignment-card {
    background-color: #fff;
    padding: 30px;
    border-radius: 12px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.assignment-content {
    border-left: 1px dashed #aed5f1;
    padding-left: 30px;
    margin-left: 15px;
    position: relative;
}

.assignment-content:before {
    position: absolute;
    content: "";
    left: -8px;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    border: 2px solid #fff;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
    background-color: #38a6de;
}

.top-title {
    color: #000;
    margin-bottom: 20px;
    font-family: "Inter", sans-serif;
}

.top-title h2 {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin-bottom: 0;
}

.assignment {
    background-color: #fff;
    padding: 30px 20px 20px;
    text-align: center;
    border-radius: 12px;
    margin-bottom: 30px;
    /* box-shadow: 0 0 10px rgb(0 0 0 / 10%); */
    border: 1px solid #efefef;
}

.assignment .thumb {
    text-align: center;
    width: 120px;
    height: 120px;
    margin-bottom: 20px;
    border-radius: 100%;
    overflow: hidden;
    display: inline-block;
}

.assignment .text {
    text-align: left;
    color: #52525b;
}

.assignment .text h3 {
    color: #0e2d54;
    font-weight: bold;
    font-size: 19px;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #aed5f1;
}

.assignment .text h4 {
    color: #0e2d54;
    font-weight: bold;
    font-size: 19px;
}

.assignment ul {
    min-height: 75px;
}

.assignment p {
    min-height: 63px;
}

.assignment ul li {
    padding-left: 15px;
    position: relative;
    margin-bottom: 15px;
}

.assignment ul li:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    left: 0;
    top: 9px;
    border-radius: 100%;
    background-color: #52525b;
}

.assignment .text h5 {
    color: #52525b;
    font-size: 14px;
    font-weight: bold;
}

.assignment .button {
    text-align: center;
}

.assignment .btn.btn-primary {
    padding: 35px 36px;
}

/*register-section*/

.register-section {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top right;
    background-color: #38a6de;
    overflow: hidden;

    /* ---- */
    position: relative;
}

.register-section > .row {
    width: 30%;
    position: absolute;
    right: 5%;
}

.register {
    width: 100%;
}

.register-content {
    max-height: 100vh;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.register-content .logo {
    margin: 130px 0 30px 0;
}

.register-title {
    color: #525252;
    margin-bottom: 20px;
}

.register-title.withoutForm {
    margin-top: 40px;
}

.register-title h1 {
    font-size: 36px;
    color: #0e2d54;
    font-weight: 800;
    font-family: "Baloo 2", cursive;
}

.register-content label {
    font-weight: bold;
    font-size: 17px;
    color: #10324b;
}

.register-content input {
    height: 55px;
    font-weight: 800;
    color: #01324b;
    font-size: 20px;
    padding: 6px 20px;
    font-family: "Baloo 2", cursive;
    border-radius: 15px;
    border: 1px solid #38a6de;
}

.register-content input::placeholder {
    color: #9ab3c2 !important;
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

.form-group .icon {
    position: absolute;
    top: 38px;
    right: 15px;
}

.register-content input::-webkit-input-placeholder {
    color: #01324b;
}

.register-content input::-moz-placeholder {
    color: #01324b;
}

.register-content input:-ms-input-placeholder {
    color: #01324b;
}

.register-content input:-moz-placeholder {
    color: #01324b;
}

.forgot-password {
    display: flex;
    justify-content: flex-end;
}

.forgot-password a {
    color: #0e2d54;
    font-weight: 600;
    font-family: "Baloo 2", cursive;
}

.register-content .btn.btn-primary {
    width: 100%;
    font-size: 22px;
    margin-top: 10px;
    padding: 45px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("src/assets/images/btn-bg1.png");
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-content .button-hover {
    width: 108%;
}

.register-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    color: #fff;
    padding-bottom: 15px;
    padding-right: 100px;
    justify-content: space-between;
}

.register-footer ul {
    display: flex;
}

.register-footer ul li {
    margin-right: 15px;
}

.register-footer ul li:last-child {
    margin-right: 0;
}

.register-footer ul a {
    color: #fff;
}

.toggle-password {
    position: relative;
    cursor: pointer;
}

.toggle-password:before {
    position: absolute;
    content: "";
    top: 15px;
    left: 0;
    width: 30px;
    height: 2px;
    background-color: #38a6de;
    transform: rotate(299deg);
    opacity: 0;
}

.toggle-password.icon-show:before {
    opacity: 1;
}

/*ds-footer*/

.ds-footer {
    display: flex;
    color: #124a67;
    justify-content: space-between;
}

.ds-footer p {
    margin-bottom: 0;
}

.ds-footer ul {
    display: flex;
}

.ds-footer ul li {
    margin-right: 15px;
}

.ds-footer ul li:last-child {
    margin-right: 0;
}

.ds-footer ul li a {
    color: #124a67;
}

.ds-footer ul li a:hover {
    color: #000;
}

/* page loader  */
#preloader {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.loader-wrapper.autoHeight {
    padding: 50px 0;
}

.loader-wrapper.autoHeight #preloader {
    position: relative;
    height: auto !important;
    margin: 0;
}

.loader3 {
    width: 5rem;
    height: 5rem;
    display: inline-block;
    padding: 0rem;
    text-align: left;
}

.loader3 span {
    position: absolute;
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background: #0e2d54;
    -webkit-animation: loader3 1.5s linear infinite;
    animation: loader3 1.5s linear infinite;
}

.loader3 span:last-child {
    animation-delay: -0.9s;
    -webkit-animation-delay: -0.9s;
}

@keyframes loader3 {
    0% {
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 0;
    }
}

@-webkit-keyframes loader3 {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.8;
    }

    100% {
        -webkit-transform: scale(1, 1);
        opacity: 0;
    }
}

a,
button,
.main-content,
.ds-sidebar,
.side-nav li a:before,
.header-cpntent,
.header,
.side-nav li a span img,
.toggle-password:before,
.logo-lg,
.logo-sm,
.ds-dropdown .icon:before {
    -webkit-transition: all 500ms;
    -moz-transition: all 500ms;
    -ms-transition: all 500ms;
    -o-transition: all 500ms;
    transition: all 500ms;
}

.dd-innermenu_active a {
    color: #38a6de !important;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuMDg2MTggOS40Nzg4NkM0LjA2NzUxIDkuNTM0ODYgNC4wNTM1MSA5LjYwMDE5IDQuMDQ0MTggOS42NzQ4NkM0LjA0NDE4IDkuNzQ5NTMgNC4wNDQxOCA5LjgxOTUzIDQuMDQ0MTggOS44ODQ4NkM0LjA0NDE4IDEwLjA1MjkgNC4wNjI4NSAxMC4yMTE1IDQuMTAwMTggMTAuMzYwOUM0LjEzNzUxIDEwLjUxMDIgNC4xOTgxOCAxMC42NDA5IDQuMjgyMTggMTAuNzUyOUM0LjM2NjE4IDEwLjg2NDkgNC40NzM1MSAxMC45NTM1IDQuNjA0MTggMTEuMDE4OUM0Ljc0NDE4IDExLjA5MzUgNC45MTY4NSAxMS4xMzA5IDUuMTIyMTggMTEuMTMwOUM1LjMxODE4IDExLjEzMDkgNS41MDk1MSAxMS4wODg5IDUuNjk2MTggMTEuMDA0OUM1Ljg5MjE4IDEwLjkyMDkgNi4xMzAxOCAxMC43OTk1IDYuNDEwMTggMTAuNjQwOUw5LjQyMDE4IDguMzIxNTNDOS42NjI4NSA4LjAwNDE5IDkuNzg0MTggNy41ODQxOSA5Ljc4NDE4IDcuMDYxNTNDOS43ODQxOCA2LjY2OTUzIDkuNzI4MTggNi4zNjYxOSA5LjYxNjE4IDYuMTUxNTNDOS41MDQxOCA1LjkzNjg2IDkuMzQwODUgNS43Njg4NiA5LjEyNjE4IDUuNjQ3NTNMNS4yNzYxOCAyLjgyNDE5QzQuODE4ODUgMi45NDU1MyA0LjUwNjE4IDMuMTA4ODYgNC4zMzgxOCAzLjMxNDE5QzQuMTc5NTEgMy41Mjg4NiA0LjEwMDE4IDMuNzU3NTMgNC4xMDAxOCA0LjAwMDE5QzQuMTAwMTggNC4zMjY4NiA0LjE3OTUxIDQuNTc4ODYgNC4zMzgxOCA0Ljc1NjE5QzQuNTA2MTggNC45NDI4NiA0Ljc0ODg1IDUuMTE1NTMgNS4wNjYxOCA1LjI3NDE5TDcuNzQyNDIgNy4wNjE1M0w0LjA4NjE4IDkuNDc4ODZaIiBmaWxsPSIjMTY1Qjk1Ii8+Cjwvc3ZnPgo=);
    margin-top: 3px;
}

.breadcrumb-item a,
.breadcrumb-item {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #0e2d54;
}

.breadcrumb-item.active {
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    color: #0e2d54;
}

.fancy_tabs .nav-tabs {
    background: none;
    box-shadow: none;
    border: 0;
    margin: 25px 0;
}

.fancy_tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: 0;
    color: #ffffff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIyIiBoZWlnaHQ9IjQ5IiB2aWV3Qm94PSIwIDAgMTIyIDQ5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOS4yMjA3IDQ1LjY2ODdDMjMuMTU3OSA1MC43ODQ0IDY5LjA2NzcgNDkuNzQ4OSAxMTAuMDI1IDQ0LjMzMzhDMTE3LjEyOSA0My4zOTQ1IDEyMi40MiAzNy4wNzYxIDEyMS45NDEgMjkuOTI2QzEyMS4zMDYgMjAuNDMzMSAxMTkuNTkxIDEwLjkxNTcgMTEzLjA3MyA3LjU2Njc0QzEwMy41MjYgMi42NjE4MiAzMC41NzE0IC0xLjk1MjMgMTQuNjU5OCAwLjg1MDUxQy0xLjI1MTc4IDMuNjUzMzMgLTQuNzE2NDUgNDAuNTUzMSA5LjIyMDcgNDUuNjY4N1oiIGZpbGw9IiMzRDc4OTciLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff0;
}

.nav-tabs .nav-link {
    color: #3d7897;
    font-weight: 600;
    font-size: 19px;
    line-height: 25px;
    align-items: center;
    text-align: center;
    padding: 12px 30px;
    border: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: none;
}

.analysis_box {
    background: #ffffff;
    border: 1px solid #f3f1f1;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    padding: 12px;
}

.analysis_box .ana_cont {
    flex: 1;
    margin-left: 16px;
}

.ana_box_border {
    border: 1px dashed #aed5f1;
    border-radius: 11px;
    display: flex;
    align-items: center;
    padding: 12px;
}

.ana_box_border img {
    min-width: 84px;
    width: 84px;
}

.analysis_box .ana_cont h4 {
    font-weight: 600;
    color: #165b95;
}

.analysis_box .ana_cont p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #165b95;
    margin-bottom: 0;
    margin-top: 9px;
}

.progress {
    background: #aed5f1;
    height: 7px;
}

.progress-bar {
    background: linear-gradient(358.51deg, #32a3dd 17.07%, #32a3dd 58.94%, #1180bf 97.37%);
    /* border-radius: 5px; */
}

.sec_body {
    background-color: #fff;
    padding: 25px;
    border-radius: 12px;
    margin-bottom: 30px;
    box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

.sec_body .btn-group {
    gap: 16px;
}

.kid_name h4 {
    font-weight: 700;
    font-size: 19.3816px;
    line-height: 24px;
    /* identical to box height */

    color: #0e2d54;
}

.kid_name p {
    font-weight: 400;
    font-size: 11.6289px;
    line-height: 14px;

    /* Grey Scale/Grey 700 */

    color: #3f3f46;
}

.kid_card hr {
    border: 0.969079px solid #aed5f1;
}

.kid_score_each {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.kid_score_each h5 {
    font-weight: 600;
    font-size: 15.5053px;
    line-height: 23px;
    /* identical to box height, or 150% */

    /* Grey Scale/Grey 600 */

    color: #52525b;
    flex: 1;
    margin-bottom: 0;
}

.kid_score_each p {
    font-weight: 600;
    font-size: 11.6289px;
    line-height: 19px;
    /* identical to box height, or 167% */
    margin-bottom: 0;

    color: #000000;
    background: #f2f9ff;
    border-radius: 50px;
    padding: 10px 5px;
    max-width: 25px;
    text-align: center;
    min-width: 45px;
}

.kid_card .button {
    text-align: center;
}

.kid_card .btn.btn-primary {
    padding: 35px 16px;
    /* height: 80px; */
}

.kid_card {
    position: relative;
    background-color: #fff;
    padding: 30px 20px 20px;
    border-radius: 12px;
    margin-bottom: 30px;
    box-shadow: 0px 9.69079px 12.598px rgba(0, 0, 0, 0.1);
    border: 1px solid #efefef;
}

.kid_img {
    text-align: center;
    margin-bottom: 13px;
}

.kid_img_inner {
    display: inline-block;
    position: relative;
}

.cardEdit {
    position: absolute;
    top: 10px;
    right: 10px;
}

.search-form.onpage {
    width: 100%;
    margin-bottom: 25px;
}

.btn-primary-light.wt_icon,
.btn-primary-dark.wt_icon,
.btn-primary-color.wt_icon {
    display: flex;
    align-items: center;
}

.btn-primary-light.wt_icon svg,
.btn-primary-dark.wt_icon svg,
.btn-primary-color.wt_icon svg {
    margin-left: 5px;
    width: 20px;
}

.btn-primary-light,
.btn-primary-dark,
.btn-primary-color {
    border-radius: 22px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 12px 16px;
}

.btn-primary-light {
    color: #165b95;
    background: #f2f9ff;
}

.btn-primary-dark {
    color: #FFFFFF;
    background: #165B95;
}

.btn-primary-color {
    position: relative;
    overflow: hidden;
    z-index: 1;
    border-width: 0;
    color: #FFFFFF;
    background: linear-gradient(284.86deg, #ffc267 4%, #f5951d 78.58%);;
}

.btn-primary-light.md,
.btn-primary-dark.md,
.btn-primary-color.md {
    padding: 7px 20px;
}

.btn-primary-light:hover {
    background-color: #165b95 !important;
    color: #fff !important;
}

.btn-primary-dark:hover {
    background-color: #f2f9ff !important;
    color: #165b95 !important;
}

.btn-primary-color:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(284.86deg, #f5951d 4%, #ffc267 78.58%);
    opacity: 0;
    z-index: -1;
    transition: opacity .3s;
}

.btn-primary-color:hover:before {
    opacity: 1;
}

.btn-primary-color:hover {
    border-width: 0;
}

.btn-primary-light svg path {
    fill: #165b95;
}

.btn-primary-light:hover svg path {
    fill: #ffffff;
}

.btn-primary-dark svg path {
    fill: #FFFFFF;
}

.btn-primary-dark:hover svg path {
    fill: #165b95;
}

.btn-primary-color svg path {
    fill: #FFFFFF;
}

.actioned_tabbing {
    display: flex;
    align-items: center;
}

.actioned_tabbing .nav-tabs {
    flex: 1;
}


.ana_cont h4 {
    font-weight: 600;
    color: #165b95;
}

.ana_cont p {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #165b95;
    margin-bottom: 0;
    margin-top: 9px;
}

.kanban-container {
    height: 100%;
}

.main-kanban-content {
    /* transform-origin: 100% 0%;
       transition: transform .3s; */
}

.board-container {
}

.item-container {
    overflow-y: auto;
    height: calc(100vh - 8rem);
}

.moving-item {
    cursor: pointer;
}

.item-container > .moving-item:active {
    cursor: grabbing;
}

.item-container.hover {
    border: 2px dashed #0e2d54;
}

.moving-item.dragging {
    opacity: 0.3;
}

.board-container .class_name {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    margin-bottom: 25px;

    color: #000000;
}

.each_class_wrap {
    padding: 15px;
    background: #e2f3ff;
    border-radius: 20px;
}

/* Custom css */

.active_btn {
    color: #fff;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    background-color: #165b95;
    font-family: "Baloo 2", cursive;
}

.active_btn:hover {
    background-color: #e2f3ff;
    color: #165b95;
}

.active_btn.no-hover:hover {
    color: #165b95 !important;
}

.complete_btn:hover {
    background-color: #165b95;
    color: #fff;
}

.complete_btn.no-hover:hover {
    color: #165b95 !important;
}

.complete_btn {
    color: #165b95;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    background-color: #e2f3ff;
    font-family: "Baloo 2", cursive;
}

.border_top {
    border-top: 1px solid #aed5f1;
    padding: 19px 16px;
}

.nav-link2 {
    color: #165b95;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    font-family: "Baloo 2", cursive;
    background: none;
    outline: none;
    border: none;
}

.nav-link2.active,
.nav-link2:hover {
    background-color: #165b95;
    color: #fff;
}

.blue_txt {
    color: #165b95;
    font-size: 18px;
    padding: 5px 20px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    font-family: "Baloo 2", cursive;
}

.student_report_card {
    border: 1px solid #f3f1f1;
    border-radius: 12px;
    box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.1);
}

.student_report_body {
    padding: 14px 16px;
}

.student_report_card .fancy_tabs .nav-tabs .nav-item.show .nav-link,
.student_report_card .nav-tabs .nav-link.active {
    background: #165b95 none;
}

.progress_btn {
    border: 0;
    color: #ffffff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIyIiBoZWlnaHQ9IjQ5IiB2aWV3Qm94PSIwIDAgMTIyIDQ5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOS4yMjA3IDQ1LjY2ODdDMjMuMTU3OSA1MC43ODQ0IDY5LjA2NzcgNDkuNzQ4OSAxMTAuMDI1IDQ0LjMzMzhDMTE3LjEyOSA0My4zOTQ1IDEyMi40MiAzNy4wNzYxIDEyMS45NDEgMjkuOTI2QzEyMS4zMDYgMjAuNDMzMSAxMTkuNTkxIDEwLjkxNTcgMTEzLjA3MyA3LjU2Njc0QzEwMy41MjYgMi42NjE4MiAzMC41NzE0IC0xLjk1MjMgMTQuNjU5OCAwLjg1MDUxQy0xLjI1MTc4IDMuNjUzMzMgLTQuNzE2NDUgNDAuNTUzMSA5LjIyMDcgNDUuNjY4N1oiIGZpbGw9IiMzRDc4OTciLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-color: #fff0;
}

.pro_text {
    font-size: 24px;
    color: #000;
    font-weight: 800;
    margin-bottom: 15px;
    font-family: "Baloo 2", cursive;
}

.title_current h1 {
    font-weight: 600;
    font-size: 24px;
    color: #0e2d54;
}

.recent_text {
    font-weight: 600;
    font-size: 15px;
    color: #18181b;
}

.small_gray_text {
    font-weight: 400;
    font-size: 12px;
    color: #52525b;
}

.border_dash::before {
    content: "";
    position: absolute;
    height: 42px;
    border: 1px solid;
    border-style: dashed;
    border-color: #32a3dd;
    left: 25px;
    top: 50px;
}

.border_dash1::before {
    content: "";
    position: absolute;
    height: 42px;
    border: 1px solid;
    border-style: dashed;
    border-color: #32a3dd;
    left: 25px;
    top: 142px;
}

.border_dash2::before {
    content: "";
    position: absolute;
    height: 42px;
    border: 1px solid;
    border-style: dashed;
    border-color: #32a3dd;
    left: 25px;
    top: 233px;
}

.modal_btn {
    border: 0;
    color: #ffffff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIyIiBoZWlnaHQ9IjQ5IiB2aWV3Qm94PSIwIDAgMTIyIDQ5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOS4yMjA3IDQ1LjY2ODdDMjMuMTU3OSA1MC43ODQ0IDY5LjA2NzcgNDkuNzQ4OSAxMTAuMDI1IDQ0LjMzMzhDMTE3LjEyOSA0My4zOTQ1IDEyMi40MiAzNy4wNzYxIDEyMS45NDEgMjkuOTI2QzEyMS4zMDYgMjAuNDMzMSAxMTkuNTkxIDEwLjkxNTcgMTEzLjA3MyA3LjU2Njc0QzEwMy41MjYgMi42NjE4MiAzMC41NzE0IC0xLjk1MjMgMTQuNjU5OCAwLjg1MDUxQy0xLjI1MTc4IDMuNjUzMzMgLTQuNzE2NDUgNDAuNTUzMSA5LjIyMDcgNDUuNjY4N1oiIGZpbGw9IiMzRDc4OTciLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-color: #fff0;
    padding: 17px 10px;
    background-size: cover;
}

#home-tab-pane .modal_btn {
    padding: 4px 22px;
}

.stud_title {
    font-weight: 700;
    font-size: 24px;
    color: #0e2d54;
}

.assign_btn {
    font-size: 15px;
    font-weight: 700;
    padding: 5px 22px;
    background-color: #345b6f;
    border-radius: 13px;
    color: white;
    border: 0;
    font-family: "Baloo 2", cursive;
}

.assign_btn:hover {
    background-color: #345b6f;
    color: white;
    border: 0;
}

.assign_title {
    font-size: 26px;
    font-weight: 700;
    color: #0e2d54;
}

.assign_card {
    border: 1px solid #f3f1f1;
    box-shadow: 0px 13.0124px 16.9161px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    height: 100%;
}

.custom_primary_btn {
    padding: 25px 63px;
    background-color: transparent;
    background-image: url("src/assets/images/btn-bg.png");
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    font-family: "Baloo 2", cursive;
    color: white;
    opacity: 1;
    transition: opacity .3s;
}

.custom_primary_btn:disabled {
    color: #ffffff;
}

.custom_primary_btn:hover,
.custom_primary_btn:active {
    opacity: .85;
    color: white !important;
}

.zoomBtn {
    padding: 0;
    border: none;
    outline: none;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color .3s;
    width: 23px;
    height: 23px;
    flex-shrink: 0;
}

.zoomBtn:hover {
    background-color: #e2f2ff;
}

.btn.custom_secondary_btn {
    padding: 35px 63px;
    background-color: transparent;
    background-image: url("src/assets/images/btn-secondary-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    font-family: "Baloo 2", cursive;
    color: #165B95;
    opacity: 1;
    transition: opacity .3s;
}

.btn.custom_secondary_btn:hover,
.btn.custom_secondary_btn:active {
    color: #165B95 !important;
    opacity: .9;
}

.assign_text {
    font-weight: 400;
    font-size: 18.2173px;
    line-height: 31px;
    color: #52525b;
}

.assign_gren_btn {
    font-size: 13px;
    padding: 5px 10px;
    background-color: #00b14b;
    border-radius: 13px;
    color: white;
    border: 0;
}

.assign_gren_btn:hover {
    background-color: #00b14b;
    color: white;
    border: 0;
}

.add_modal_btn {
    background: linear-gradient(284.86deg, #ffc267 4%, #f5951d 78.58%);
    border-radius: 22px;
    font-size: 19px;
    padding: 5px 20px;
    color: #fff;
    font-family: "Baloo 2", cursive;
    font-weight: 600;
    border: 0;
}

.download_modal_btn {
    background: #165b95;
    border-radius: 22px;
    font-size: 19px;
    padding: 5px 20px;
    color: #fff;
    font-family: "Baloo 2", cursive;
    font-weight: 600;
    border: 0;
}

.assign_content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #0e2d54;
    margin-bottom: 15px;
}

.assign_card_box {
    border: 1px solid #f3f1f1;
    box-shadow: 0px 13px 16px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    margin-bottom: 25px;
}

.assign-active_btn {
    border: 0;
    color: #ffffff;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIyIiBoZWlnaHQ9IjQ5IiB2aWV3Qm94PSIwIDAgMTIyIDQ5IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNOS4yMjA3IDQ1LjY2ODdDMjMuMTU3OSA1MC43ODQ0IDY5LjA2NzcgNDkuNzQ4OSAxMTAuMDI1IDQ0LjMzMzhDMTE3LjEyOSA0My4zOTQ1IDEyMi40MiAzNy4wNzYxIDEyMS45NDEgMjkuOTI2QzEyMS4zMDYgMjAuNDMzMSAxMTkuNTkxIDEwLjkxNTcgMTEzLjA3MyA3LjU2Njc0QzEwMy41MjYgMi42NjE4MiAzMC41NzE0IC0xLjk1MjMgMTQuNjU5OCAwLjg1MDUxQy0xLjI1MTc4IDMuNjUzMzMgLTQuNzE2NDUgNDAuNTUzMSA5LjIyMDcgNDUuNjY4N1oiIGZpbGw9IiMzRDc4OTciLz4KPC9zdmc+Cg==);
    background-repeat: no-repeat;
    background-color: #fff0;
    padding: 15px 30px;
    padding: 15px 5px;
    background-size: contain;
}

.book_result_title {
    font-weight: 700;
    font-size: 24px;
    color: #0e2d54;
}

.book_result_text {
    font-weight: 400;
    font-size: 14px;
    color: #3f3f46;
}

.book_result_text img {
    margin-right: 10px;
}

.book_result_gray-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #52525b;
    margin-bottom: 40px;
}

.book_small_content {
    font-size: 16px;
    color: #01324b;
}

.book_big_content {
    font-size: 32px;
    color: #01324b;
    font-weight: 800;
    font-family: "Baloo 2", cursive;
}

.teacher_card {
    background: #f2f9ff;
    box-shadow: 0px 2.48974px 8.29912px -1.65982px rgba(0, 0, 0, 0.22);
    border-radius: 8.29912px;
    padding: 20px;
}

.teach_title {
    font-size: 27.6706px;
    font-weight: 800;
    font-family: "Baloo 2", cursive;
    color: #01324b;
}

.assign_card-body {
    padding: 30px;
}

.techer_blue-title {
    font-size: 24px;
    color: #0e2d54;
    font-weight: 600;
}

.techer_gray-title {
    font-weight: 500;
    font-size: 18px;
    color: #52525b;
}

.border_right::before {
    content: "";
    position: absolute;
    width: auto;
    height: 150px;
    right: 20px;
    opacity: 0.2;
    border-right: 1px solid #000000;
}

.title_libary .button a {
    color: #fff;
    font-size: 18px;
    padding: 11px 30px;
    border-radius: 20px;
    font-weight: 600;
    display: inline-block;
    background-color: #165b95;
    font-family: "Baloo 2", cursive;
    display: flex;
    align-items: center;
    gap: 0 10px;
}

/* library css */
.see-all-btn {
    width: 100%;
    max-width: 160px;
    height: auto;
    background-color: #165b95;
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 35px;
    padding: 6px 30px;
    border-radius: 20px;
}

.see-all-btn:hover {
    color: #165b95 !important;
    background-color: #e2f3ff !important;
}

.see-Abtn .btn:hover,
.btn:first-child:hover,
:not(.btn-check) + .btn:hover {
    /* background-color: #165B95; */
    color: #fff;
}

/* library css end here*/

.tech_title {
    font-size: 36px;
    color: #0e2d54;
    font-weight: 800;
    margin-bottom: 15px;
    font-family: "Baloo 2", cursive;
}

.modal_bg {
    background-image: url("src/assets/images/modal-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
}

.modal_bg .modal-title {
    font-size: 36px;
    color: #0e2d54;
    font-weight: 800;
    margin-bottom: 15px;
    font-family: "Baloo 2", cursive;
}

.fiter_text {
    font-weight: 700;
    font-size: 17px;
    color: #01324b;
    text-align: start;
}

.title_libary {
    margin-bottom: 25px;
}

.modal_cancel_btn {
    padding: 35px 50px;
    background-color: transparent;
    background-image: url("src/assets/images/cancel_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 0;
    color: #01324b;
    border: 0;
    font-weight: bold;
    font-size: 14px;
    font-family: "Baloo 2", cursive;
}

/* custom chart css */
.chartMenu {
    width: 100%;
    height: 40px;
    background: #1a1a1a;
    color: rgb(26, 182, 255);
}

.chartMenu {
    padding: 10px;
    font-size: 20px;
}

/* .chartCard {
    width: 400px;
    height: calc(100vh - 30px);
  } */

.chartBox {
    /* width: 400px; */
    height: 500px;
    padding-bottom: 20px;
    border-radius: 20px;
    border: solid 3px rgb(230, 209, 216);
    background: rgb(255, 255, 255);
}

canvas {
    margin-bottom: 100px;
}

.button,
.button1 {
    /* display: inline-block; */
    margin: 0px 20px;
}

/* .button {
    width: 9rem;
    padding: 5px;
    float: left;
    margin-left: 45rem;
    margin-top: -2.1rem;
    cursor: pointer;
    background-color: rgb(19, 77, 241);
    border-radius: 20%;
    color: rgb(248, 249, 249);
    font-size: 110%;
    font-weight: bold;
  } */

/* .button1 {
    width: 5rem;
    padding: 5px;
    float: left;
    margin-left: 39rem;
    cursor: pointer;
    background-color: rgb(248, 249, 249);
    border-radius: 20%;
    color: rgb(19, 77, 241);
    font-size: 110%;
    font-weight: bold;
  } */
/* custom chart css */
/* modal css */
.custom-modal-close {
    width: 25px;
    height: 25px;
    border-radius: 50px;
    background-color: #fff;
}

/* modal css  end here*/
/**** CHART *****/
.chart-title {
    color: #000000;
    font-weight: 400;
    font-size: 24px;
    font-family: "Baloo 2", cursive;
    margin-bottom: 10px;
}

.chartMenu {
    width: 100%;
    height: 40px;
    background: #1a1a1a;
    color: rgb(26, 182, 255);
}

.chartMenu {
    padding: 10px;
    font-size: 20px;
}

/* .chartCard {
    width: 400px;
    height: calc(100vh - 30px);
  } */

.chartBox {
    /* width: 400px; */
    height: 500px;
    padding-bottom: 50px;
    padding-top: 32px;
    padding-left: 22px;
    padding-right: 22px;
    border-radius: 20px;
    border: none;
    background: rgb(255, 255, 255);
}

canvas {
    margin-bottom: 20px;
}

.grph-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.grph-btn {
    display: flex;
    align-items: center;
    gap: 25px;
}

.grph-btn .btn {
    flex-shrink: 0;
}

.btn-edit {
    background: #f2f9ff;
    border-radius: 22px;
    width: max-content;
    height: 40px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #165b95;
    font-weight: 400;
    font-family: "Baloo 2", cursive !important;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
}

.btn-edit:hover {
    background: #165b95 !important;
    color: #fff !important;
}

.btn-error {
    background: #cb1e5c;
    border-radius: 22px;
    width: 105.75px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f2f9ff;
    font-weight: 400;
    font-family: "Baloo 2", cursive !important;
    font-weight: bold;
    font-size: 16px;
    margin-right: 20px;
}

.btn-error:hover {
    background: #f2f9ff !important;
    color: #cb1e5c !important;
}

.btn-edit span {
    margin-left: 8px;
}

.btn-edit:hover svg path {
    stroke: #fff;
}

.btn-edit:hover svg rect {
    fill: #fff;
}

.btn-edit-round {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 38px;
    height: 38px;
    background-color: #DFEFFB;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.14);
    border-radius: 50%;
    border: none;
    outline: none;
    transition: background-color .3s;
}

.btn-edit-round:hover {
    background-color: #a1d3ef;
}

.btn-class {
    background: #165b95;
    border-radius: 22px;
    width: max-content;
    padding: 0 15px;
    height: 40px;
    border: none;
    font-weight: bold;
    color: #fff;
    font-size: 19px;
    font-family: "Baloo 2", cursive !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-class span {
    margin-left: 8px;
}

.kid_card-edit {
    background: #dfeffb;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: 0;
    filter: drop-shadow(0px 3.87632px 3.87632px rgba(0, 0, 0, 0.14));
}

.back-btn {
    position: absolute;
    left: 0;
    top: 35px;
    z-index: 100;
}

.back-btn a {
    display: flex;
    align-items: center;
    color: #01324b;
    font-weight: bold;
    font-size: 14px;
    font-family: "Baloo 2", cursive !important;
}

.back-btn a img {
    margin-right: 8px;
}

.title_libary-btn .button a {
    justify-content: center;
    padding: 11px 10px;
}

.modal_btn-compare {
    padding: 17px 37px;
}

.teach_reso .assign_card .card-body h5 {
    font-weight: 700;
    font-size: 19.3816px;
    color: #0e2d54;
    margin: 30px 0 3px 0;
}

.teach_reso .assign_card .card-body p {
    font-weight: 400;
    font-size: 11.6289px;
    color: #3f3f46;
}

/********/
.student-edit-modal .modal {
    z-index: 10000;
}

.student-edit-modal .modal-dialog {
    max-width: 793px;
    width: 100%;
}

.student-edit-modal .modal-header {
    padding: 41px 32px;
    background-position: bottom center;
}

.btn-close {
    background: #ffffff;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    opacity: 1;
}

.student-edit-modal .modal-title {
    margin: 0;
    line-height: 1;
}

.student-edit-modal .modal-body {
    padding: 40px 44px 30px;
}

.student-edit-top h4 {
    color: #01324b;
    font-weight: 700;
    font-size: 17px;
    font-family: "Montserrat", sans-serif;
    margin: 0 0 30px 0;
}

.student-edit-top .image-holder {
    text-align: center;
}

.image-holder-inner {
    border: 4px solid #0e2d54;
    position: relative;
    display: inline-block;
    border-radius: 50%;
    padding: 4px;
}

.student-edit-form {
    margin: 35px 0 0 0;
}

form .form-group .label-text {
    color: #01324b;
    font-weight: 700;
    font-size: 17px;
    font-family: "Montserrat", sans-serif;
    display: block;
    margin: 0 0 5px 0;
}

form .form-group .form-control {
    font-weight: 700;
    font-size: 18px;
    color: #165b95;
    font-family: "Baloo 2", cursive !important;
    background: #ffffff;
    border: none;
    border: 2px solid #38a6de;
    border-radius: 14px;
    height: 48px;
    padding: 0 0 0 26px;
}

form .form-group textarea.form-control {
    height: 115px;
    color: #9ab3c2;
    padding: 10px;
}

form .form-group textarea.form-control::placeholder {
    color: #9ab3c2;
}

form .form-group .form-control::placeholder {
    color: #165b95;
}

form .form-group textarea.form-control {
    color: #165b95;
}

.formSubmitError {
    color: #b02a37;
    font-weight: 700;
    font-size: 16px;
}

.relative {
    position: relative;
}

.down-img {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 34px;
}

.btn-submit {
    background-image: url("src/assets/images/button-bg.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    font-weight: 700;
    font-family: "Baloo 2", cursive !important;
    color: #fff;
    font-size: 22px;
    max-width: 210px;
    width: 100%;
    height: 63px;
    padding: 0 !important;
    border: none;
    margin: 0;
    background-color: transparent;
    transition: opacity .3s;
}

.btn-submit:hover,
.btn-cancel:hover {
    opacity: .8;
}

.btn-submit[disabled] {
    opacity: .5;
    pointer-events: none;
    filter: grayscale(1);
}

.btn-cancel {
    background-image: url("src/assets/images/cancel_bg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 210px;
    width: 100%;
    height: 63px;
    padding: 0 !important;
    color: #165b95;
    font-weight: 700;
    font-family: "Baloo 2", cursive !important;
    font-size: 22px;
    border: none;
    background-color: transparent;
    margin: 0 20px 0 0;
}

.btn-cancel:hover {
    color: #165b95;
}

.add-photo-modal .modal-body {
    padding: 30px 25px 20px;
}

.add-photo-modal .modal-footer {
    display: flex;
    align-items: center;
    padding: 0 25px 30px;
    justify-content: space-between;
}

.zoom-in {
    margin: 0;
}

.add-photo-modal .modal-footer .media {
    display: flex;
    align-items: center;
    margin: 0;
}

.btn-cancel {
    height: 57px;
    font-size: 19px;
    width: 190px;
    margin-right: 18px;
}

.btn-submit {
    height: 57px;
    position: relative;
    font-size: 19px;
    width: 190px;
}

.btn-submit:disabled {
    opacity: 0.6;
}

.btn-submit .button-hover {
    width: 116%;
}

.btn-submit .button-hover:hover {
    opacity: 1;
}

/***** ASSIGN BOOK MODAL *******/
.assign-book-modal .modal {
    z-index: 10000;
}

.assign-book-modal .modal-header {
    padding: 25px 32px 57px;
    background-position: bottom center;
}

.assign-book-modal .modal-dialog {
    max-width: 1534px;
    width: 100%;
}

.assign-book-inner .course-top .text h2 {
    color: #0e2d54;
}

.assign-book-modal .modal-body {
    padding: 25px 30px;
}

.tech_title {
    font-weight: 700;
    font-size: 17px;
    color: #01324b;
}

.search-form form input {
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.11);
}

/***** ADD ASSIGNMENTMODAL *******/
.add-assignment-modal .modal-dialog {
    max-width: 1035px;
}

.select-text {
    font-weight: 400;
    font-size: 17px;
    line-height: 21px;
    color: #01324b;
    font-family: "Montserrat", sans-serif;
}

.add-assignment-modal form .form-group .form-control::placeholder {
    color: #9ab3c2;
}

.add-assignment-modal form .form-group select.form-control {
    color: #9ab3c2;
}

/****** COMPARE CALENDAR MODAL ******/
.compare-calendar-modal .modal-dialog {
    max-width: 1006px;
}

.compare-calendar-modal .modal-body {
    padding: 0;
}

.form-control-sm {
    display: none;
}

.lightpick {
    padding: 0;
    border-radius: 10px 10px 0 0;
    box-shadow: none;
    display: block;
}

.lightpick__inner {
    border-radius: 10px 10px 0 0;
}

.lightpick__months {
    border-radius: 10px 10px 0 0;
}

.lightpick__month {
    padding: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
}

.lightpick__month-title-bar {
    position: relative;
    background: url("src/assets/images/modal-bg.png") no-repeat;
    background-position: bottom center;
    background-size: cover;
    padding: 50px 0;
    margin-bottom: 0;
    justify-content: center;
    border-radius: 10px 10px 0 0;
}

.lightpick__month-title {
    margin: 0;
    font-weight: 700;
    font-size: 36px;
    color: #0e2d54;
    font-family: "Baloo 2", cursive !important;
}

.lightpick__month-title > .lightpick__select-months {
    color: #0e2d54;
}

.lightpick__toolbar {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.lightpick__month-title > .lightpick__select-years {
    font-family: "Baloo 2", cursive !important;
    color: #0e2d54;
    font-weight: 700;
    background: url("src/assets/images/calendar-down-arrow.svg") no-repeat;
    background-position: 100% center;
    background-size: 15px;
    font-size: 36px;
    margin-left: -10px;
    padding-right: 15px;
}

.lightpick__days-of-the-week {
    padding: 0 50px;
}

.lightpick__days {
    padding: 0 40px;
}

.lightpick__day-of-the-week {
    color: #165b95;
    font-weight: 600;
    padding: 36px 0 70px;
    font-size: 16px;
    font-family: "Inter", sans-serif;
}

.lightpick__day {
    color: #165b95;
    font-weight: 600;
    font-size: 16px;
    font-family: "Inter", sans-serif;
    height: 56px;
    width: 56px;
    margin: 25px auto;
    border-radius: 50%;
    cursor: pointer;
}

.lightpick__day.is-available.is-previous-month {
    color: #9ab3c2;
    font-weight: 600;
    font-size: 16px;
    opacity: 1;
}

.lightpick__day.is-start-date,
.lightpick__day.is-end-date,
.lightpick__day.is-start-date:hover,
.lightpick__day.is-end-date:hover {
    background: #165b95 none;
}

.lightpick__day.is-today {
    background: #165b95;
    color: #fff;
}

.lightpick__previous-action,
.lightpick__next-action,
.lightpick__close-action {
    margin-left: 0;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    border-radius: 0;
    background-color: transparent;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1000;
    padding: 0;
}

.lightpick__previous-action {
    left: -175px;
    background: url("src/assets/images/left-arrow.svg") no-repeat;
    background-size: 10px;
    background-position: center center;
}

.lightpick__next-action {
    right: -180px;
    background: url("src/assets/images/right-arrow.svg") no-repeat;
    background-size: 10px;
    background-position: center center;
}

.lightpick__select option {
    font-size: 18px;
    padding: 20px;
}

.lightpick__month-title > .lightpick__select {
    /* border: none; */
    background-color: transparent;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-align: center;
    appearance: none;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
    background-color: transparent;
    color: #0e2d54;
}

.btn:focus-visible {
    box-shadow: none;
}

.btn-secondary {
    box-shadow: none;
    padding: 0;
}

.lightpick__month-title > .lightpick__select:hover {
    color: #0e2d54;
}

.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
    box-shadow: none;
}

/* .icon-chevron {
    display: block;
    width: 15px;
    height: 22px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAWCAMAAAAo0TYrAAAAElBMVEX///8AAAAAAAAAAAAAAAAAAABknMCaAAAABXRSTlMAEHCg0O5XV0MAAAAzSURBVHgBYwACJkYGZMDMysKIwmUFCyC4KAIsrKgCjPQQQPCpzUX4B9O/mOGBGV7o4QkA5zcCv3HiEZgAAAAASUVORK5CYII=);
}

.icon-chevron-rotate {
    transform: rotate(180deg);
} */
/*
.page {
    width: 100%;
    margin: 0 auto;
    padding: 0;
}

.responsive-calendar-modal {
    background-color: #1d86c8;
    color: white;
    padding: 1em;
}

.responsive-calendar .controls {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px 0;
    background-position: bottom center;
    padding: 44px 0;
    border-radius: 10px 10px 0 0;
}

.responsive-calendar .controls a {
    cursor: pointer;
    color: red;
}

.responsive-calendar .controls h4 {
    display: flex;
    line-height: 1;
    font-weight: 700;
    color: #0E2D54;
    margin: 0 20px;
    font-size: 36px;
    align-items: center;
    font-family: 'Baloo 2', cursive !important;
}

.calendar-down {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.responsive-calendar .day-headers,
.responsive-calendar .days {
    font-size: 0;
}

.responsive-calendar .day {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    position: relative;
    color: #165B95;
    font-weight: 600;
    font-size: 16px;
    width: 14.2857142857%;
    text-align: center;
    padding: 25px 0;
}

.responsive-calendar .day a {
    color: #165B95;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    padding: 0;
    font-family: 'Inter', sans-serif;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.responsive-calendar .day a:hover {
    background: #165B95;
    color: #fff;
    text-decoration: none;
}


.responsive-calendar .day.today a {
    background-color: #1d86c8;
    color: #ffffff;
}

.responsive-calendar .day.active a {
    background-color: #1d86c8;
    color: #ffffff;
}

.responsive-calendar .day.active a:hover {
    background-color: #1d86c8;
    color: #ffffff;
}

.responsive-calendar .day.active .not-current {
    background-color: #8fcaef;
    color: #ffffff;
}

.responsive-calendar .day.active .not-current:hover {
    background-color: #bcdff5;
}

.responsive-calendar .day.not-current a {
    color: #9AB3C2;
}

.responsive-calendar .day .badge {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 1;
}

.responsive-calendar .pull-left .btn {
    padding: 0;
    border: none;
}

.responsive-calendar .pull-left .btn img {
    margin: 0;
}

.responsive-calendar .pull-right .btn {
    padding: 0;
    border: none;
}

.responsive-calendar .pull-right .btn img {
    margin: 0;
} */

.compare-calendar-modal .modal-footer {
    border: none;
    padding: 10px 70px 30px;
}

.compare-calendar-modal .modal-content {
    border: none;
    border-radius: 10px;
}

.compare-calendar-modal .modal {
    z-index: 10000;
}

/******/
.accordion-item {
    margin-bottom: 25px;
    box-shadow: 0px 13px 16px rgb(0 0 0 / 10%);
    border-radius: 16px;
    border: none;
    margin-bottom: 25px;
}

.accordion-item:first-of-type {
    border: none;
}

.accordion-item:first-of-type .accordion-button {
    border: none;
}

.accordion-button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 33px 20px;
}

.accordion-button span {
    font-weight: 700;
    font-size: 26.0247px;
    color: #0e2d54;
    font-family: "Montserrat", sans-serif;
}

.accordion-button p {
    font-weight: 400;
    font-size: 17px;
    line-height: 1;
    color: #01324b;
    font-family: "Montserrat", sans-serif;
}

.accordion-button::after {
    display: none;
}

.accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-body {
    padding: 0 20px 20px;
}

.accordion-button span svg {
    transition: all 0.5s ease-in-out;
}

.accordion-button:not(.collapsed) p span svg {
    transform: rotate(-180deg);
    transition: all 0.5s ease-in-out;
}

/******* FILTER MODAL ******/
.library-media {
    display: flex;
    align-items: center;
}

.title_libary {
    flex: 1;
    width: 100%;
    margin-left: 10px;
}

.title_libary-btn .button a {
    justify-content: center;
    padding: 0;
    height: 48px;
    width: 108px;
}

.modal {
    z-index: 10000;
}

.modal-content {
    border: none;
}

.filter-modal-sec .modal-dialog {
    max-width: 1058px;
    width: 100%;
}

.filter-modal-sec .modal_bg {
    background-position: bottom center;
    background: url("src/assets/images/modal-bg.svg") no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 30px 33px 40px;
}

.filter-modal-sec .modal-body {
    padding: 25px 33px 28px;
}

.filter-modal-sec .modal-footer {
    border: none;
    padding: 0 33px 36px;
}

.filter-modal-sec .search-number ul {
    display: flex;
    align-items: center;
    padding-bottom: 26px;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.filter-modal-sec .search-number ul li {
    padding: 4px 4px;
}

.filter-modal-sec .search-number ul li a {
    font-weight: 700;
    font-size: 14px;
    color: #165b95;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border: 1px solid #f2f9ff;
    border-radius: 4px;
    height: 32px;
    padding: 0;
    width: 32px;
}

.filter-modal-sec .search-number ul li a:hover,
.filter-modal-sec .search-number ul li.active a {
    background: #165b95;
    color: #fff;
    border: 1px solid #38a6de;
}

/******/
#ui-datepicker-div {
    display: none;
    background-color: #fff;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    color: #165b95;
    font-weight: 600;
    width: 14.2857142857%;
}

.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}

.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 1;
    transition: 0.3s all;
    padding: 10px;
    color: #165b95;
    font-size: 14px;
    text-decoration: none;
}

.ui-datepicker-calendar tbody td a:hover {
    background-color: #e0f2f1;
}

.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: #165b95;
    color: white;
}

.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}

.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #eceff1;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}

.ui-datepicker-header a > span {
    display: none;
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: #78909c;
    font-weight: 400;
    font-size: 0.75rem;
}

/***** EXERCISE MODAL *******/
.Exercise-modal .modal-dialog {
    max-width: 1056px;
    width: 100%;
}

.Exercise-modal .modal_bg {
    background-position: bottom center;
    background: url("src/assets/images/modal-bg.svg") no-repeat;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 25px 33px 15px;
}

.Exercise-modal .modal-body {
    padding: 25px 33px;
}

.Exercise-modal .image-holder {
    text-align: center;
    margin: 30px 0 0 0;
}

.Exercise-modal .modal-footer {
    border: none;
    padding: 0 33px 60px;
}

.nav-tabs .nav-link.vocabulary-btn.active {
    border: 0;
    color: #ffffff;
    background-image: url("src/assets/images/btn-bg-big.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 12px 20px;
}

.nav-tabs .nav-link.vocabulary-btn2.active {
    background-image: url("src/assets/images/btn-bg-big.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 12px 45px;
}

.btn.btn-primary:hover .button-hover {
    opacity: 1;
    transition: all 0.5s ease-in-out;
}

.button-hover {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 100%;
    transition: all 0.5s ease-in-out;
    opacity: 0;
}

.custom_primary_btn {
    position: relative;
    padding: 30px 75px;
}

.custom_primary_btn .button-hover {
    width: 106%;
}

.custom_primary_btn:hover .button-hover {
    opacity: 1;
}

.student-edit-modal .modal-content {
    overflow: hidden;
}

.add-assignment-modal .modal-body {
    max-height: 75vh;
    overflow-y: initial;
}


.student-edit-modal .book-list-wrapper {
    height: 400px;
    overflow: auto;
}

.student-edit-modal .modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 15px 25px;
    background-color: #fff;
    border-top: 1px solid #e2f2ff;
    z-index: 100;
}

.student-edit-modal .modal-footer .tabs-wrapper {
    flex-wrap: wrap;
}

.student-edit-modal .modal-footer .tab {
    flex-shrink: 0;
}

.mt-36 {
    margin-top: 36px;
}


/*media*/
@media (max-width: 1099px) {
    .student-edit-modal .modal-footer {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
    }
}


@media (max-width: 550px) {
    .student-edit-modal .modal-footer {
        position: relative;
        bottom: inherit;
        left: inherit;
        right: inherit;
        padding: 15px;
    }

    .student-edit-modal .modal-body {
        overflow-y: auto;
    }

    .student-edit-modal .book-list-wrapper {
        height: 280px;
    }
}

@media screen and (max-width: 768px) {
    h2 {
        font-size: 22px;
    }
}

@media screen and (max-height: 780px) {
    .ds-sidebar {
        min-height: max-content;
        overflow-y: auto;
    }

    .sidebar-footer {
        position: relative;
        bottom: inherit;
        left: inherit;
    }

    .side-nav {
        margin-bottom: 15px;
        padding-bottom: 0;
    }

    .sidebar-footer .thumb {
        display: none;
    }
}

.disabled {
    opacity: .8;
    pointer-events: none;
}

.powerbi-container {
    width: 100%;
    height: 500px;
  }

.mb0 {
    margin-bottom: 0;
}

.date-range-selector-item {
    display: flex;
    flex-direction: column;
}

/*Datepicker overrides*/
.react-datepicker__day {
    transition: all 0.3s;
}

.react-datepicker__day--selected {
    background-color: #0d2d54 !important;
    color: white !important;
}

.react-datepicker__day--selected:hover {
    background-color: #32a2dc !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: #32a2dc !important;
    color: white !important;
}

.react-datepicker-wrapper {
    position: relative;
    width: max-content;
    z-index: 1;
}

.react-datepicker__input-container {
    width: max-content;
    position: relative;
}

.react-datepicker__input-container::before {
    content: '📅';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    font-size: 20px;
    color: #165b95;
    z-index: 10;
}


/*report table*/
.report-table-container {
    max-height: 350px;
    overflow-y: auto;
    width: 100%;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.report-table-container::-webkit-scrollbar {
    display: none;
}

.report-table {
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
}

.report-table thead th {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 0;
}

.report-table tbody {
    overflow-y: auto;
    display: table-row-group;
    width: 100%;
}

.report-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
}

.report-table tbody tr:nth-child(odd) {
    background-color: #ffffff;
}

.report-table th,
.report-table td {
    padding: 0.5rem;
    text-align: left;
    word-wrap: break-word;
    width: auto;
}

/*report chart*/
.report-chart-wrapper {
   display: flex;
    flex-direction: column;
    gap: 20px;
}

.report-chart-container {
    background-color: #f8f8f8;
}

.report-chart-toggle-buttons .toggle-button {
    height: 45px;
    background-color: #FFFFFF;
    border: 2px solid #0d2d54;
    color: #0d2d54;
    font-family: "Baloo 2", cursive;
    padding: 0 16px;
    transition: all 0.3s;
}

.report-chart-toggle-buttons .toggle-button.active {
    height: 45px;
    background-color: #0d2d54;
    color: #FFFFFF;
    font-family: "Baloo 2", cursive;
    padding: 0 16px;
    transition: all 0.3s;
}



body {
    padding: 0 !important;
}

.loader-wrapper {
    position: relative;
}

.ds-dropdown-menu.slide-toggle-content {
    display: block;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: max-height 350ms ease-in, opacity 250ms ease-in;
}

.ds-dropdown-menu.slide-toggle-content.open {
    max-height: 300px;
    opacity: 1;
    pointer-events: initial;
}

@media (min-width: 1200px) and (max-width: 1399px) {
    .sidebar-active .ds-dropdown-menu.slide-toggle-content {
        pointer-events: initial;
        opacity: 1;
    }
}

.main-content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main-content .ds-footer {
    margin-top: auto;
}

.course-top .thumb {
    padding: 30px 0 0 10px;
    min-width: 128px;
    max-width: 128px;
    position: relative;
    width: 128px;
}

.course-top .thumb img {
    max-width: 100px;
    height: auto;
    object-fit: contain;
    position: relative;
    box-shadow: 0 8px 28px rgba(15, 15, 15, 0.26);
    border-radius: 6px;
    overflow: hidden;
    transform: rotate(-5deg);
    z-index: 99;
}

.course-top .text ul li {
    flex-wrap: wrap;
}

.user-dropdown .dropdown-toggle img {
    max-width: 45px;
}

.ds-card.sec_body {
    min-height: 300px;
    position: relative;
}

.ds-card.sec_body.autoHeight {
    min-height: auto;
}

.ds-card.sec_body #preloader {
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 300px;
}

.ds-card .course {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
}

.ds-card .course .button {
    margin: auto 0 0;
}

.ds-card .back-to-library:hover {
    color: #165b95 !important;
    background-color: #e2f3ff !important;
}

.static-loader {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    position: relative;
}

.static-loader #preloader {
    position: absolute;
    background-color: transparent;
    width: fit-content;
    height: fit-content;
}

.infinite-scroll-component {
    background-color: transparent;
    scrollbar-color: transaprent;
    scrollbar-face-color: transparent;
}

.infinite-scroll-component::-webkit-scrollbar {
    width: 0 !important;
}

.infinite-scroll-component::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.2);
}

@media only screen and (min-width: 992px) and (max-width: 1399px) {
    .books-by-categories .col-lg-6:nth-child(3) {
        display: none;
    }
}

.search-form .btn-clear-search {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 30px;
    width: 30px;
    right: 7px;
    top: 10px;
}

.search-form .btn-clear-search:active {
    border: none;
}

.search-form .btn-clear-search img {
    margin: 0;
}

.btn-outline-primary {
    border-color: #165b95;
    color: #165b95;
}

.btn.btn-outline-primary:hover,
.btn.btn-outline-primary.active,
.filter-modal-sec .btn.btn-outline-primary.active:hover {
    color: #fff;
    background-color: #165b95;
}

.filter-modal-sec .btn-link {
    color: #165b95;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

.filter-modal-sec .btn-link:hover {
    color: #165b95;
    text-decoration: none;
}

.filter-modal-sec .btn.btn-outline-primary:hover {
    background-color: #ededee;
    color: #165b95;
}

.kid_card .btn-primary {
    background-size: contain;
}

.fancy_tabs + .right_btn {
    margin: 0 0 0 auto;
}

.kid_img {
    align-items: center;
    background-color: #f2f9ff;
    display: flex;
    justify-content: center;
    margin: 0 auto 13px;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;
}

.kid_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.kid_img.bordered {
    border: 2px solid #0d2d54;
}

.kid_img.bordered img {
    margin: 2px;
}

.kid_img svg {
    height: 40px;
    fill: #165b95;
    width: 40px;
}

.kid_img svg.successIcon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.student_report_card .profile-image {
    align-items: center;
    background-color: #f2f9ff;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    height: 155px;
    overflow: hidden;
    width: 168px;
}

.student_report_card .profile-image img {
    height: 100%;
    width: 100%;
}

.student_report_card .profile-image svg {
    height: 40px;
    fill: #165b95;
    width: 40px;
}

.student_class_card.kid_card {
    cursor: pointer;
}

.student_class_card.kid_card.selected {
    border-color: #165b95;
    transition: all 0.2s ease-in;
}

.student_class_card.kid_img {
    background-color: transparent;
    border-radius: 0;
    overflow: visible;
    position: relative;
}

.student_class_card.kid_img_inner {
    align-items: center;
    background-color: #f2f9ff;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    height: 120px;
    width: 120px;
}

.student_class_card.kid_card .kid_card-edit img {
    height: 21px;
    width: 21px;
}

.ana_box_border {
    align-items: flex-start;
    height: 100%;
}

.ana_box_border img {
    object-fit: contain;
}

.class_analysis .analysis_box {
    height: 100%;
}

.assign_card .image-wrapper {
    margin: 0 auto;
    padding: 5px 0;
    min-width: 150px;
    max-width: 150px;
    position: relative;
    width: 150px;
}

.assign_card .image-wrapper img {
    max-width: 130px;
    height: auto;
    object-fit: contain;
    position: relative;
    box-shadow: 0 8px 28px rgba(15, 15, 15, 0.26);
    border-radius: 6px;
    overflow: hidden;
    transform: rotate(-5deg);
    z-index: 99;
}

.assign_card .card-body {
    display: flex;
    flex-direction: column;
}

.assign_card .card-body .button {
    margin-top: auto;
}

.form-group .react-datepicker {
    background-color: #fff;
    border: none;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    font-family: "Montserrat", sans-serif;
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.form-group .react-datepicker__day-name,
.form-group .react-datepicker__day,
.form-group .react-datepicker__time-name {
    color: #165b95;
    border-radius: 0.25rem;
    line-height: 1;
    transition: 0.3s all;
    padding: 10px;
    width: 2.5rem;
    font-size: 14px;
    margin: 0;
}

.form-group .react-datepicker__day-name {
    font-weight: 600;
    font-size: 0.75rem;
}

.form-group .react-datepicker__day--outside-month {
    opacity: 0;
    pointer-events: none;
}

.form-group .react-datepicker__day--selected,
.form-group .react-datepicker__day--in-selecting-range,
.form-group .react-datepicker__day--in-range,
.form-group .react-datepicker__month-text--selected,
.form-group .react-datepicker__month-text--in-selecting-range,
.form-group .react-datepicker__month-text--in-range,
.form-group .react-datepicker__quarter-text--selected,
.form-group .react-datepicker__quarter-text--in-selecting-range,
.form-group .react-datepicker__quarter-text--in-range,
.form-group .react-datepicker__year-text--selected,
.form-group .react-datepicker__year-text--in-selecting-range,
.form-group .react-datepicker__year-text--in-range {
    background-color: #165b95;
    color: white;
}

.form-group .react-datepicker-popper {
    z-index: 100;
}

.form-group .react-datepicker__header {
    background-color: #fff;
    border: none;
}

.form-group .react-datepicker__year-read-view--down-arrow,
.form-group .react-datepicker__month-read-view--down-arrow,
.form-group .react-datepicker__month-year-read-view--down-arrow,
.form-group .react-datepicker__navigation-icon::before {
    top: 16px;
}

.form-group .react-datepicker__day--keyboard-selected:hover,
.form-group .react-datepicker__month-text--keyboard-selected:hover,
.form-group .react-datepicker__quarter-text--keyboard-selected:hover,
.form-group .react-datepicker__year-text--keyboard-selected:hover {
    background-color: #e0f2f1;
}

.assign-book-inner .search-form.onpage {
    margin: 0;
}

.assign-book-inner .infinite-scroll-component.row {
    margin: 0;
}

.course.active {
    background-color: #cae2ff;
    border-radius: 5px;
}

.student-edit-form .modal-footer {
    padding: 25px 0;
}

.react-pagination {
    display: flex;
    gap: 5px;
}

.react-pagination a {
    align-items: center;
    background: #ffffff;
    border: 1px solid #e2f3ff;
    border-radius: 4px;
    color: #165b95;
    font-family: "Baloo 2";
    font-size: 14px;
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;
}

.react-pagination .selected a,
.react-pagination .selected a:hover {
    background: #165b95;
    border: 1px solid #38a6de;
    color: #fff;
}

.react-pagination a:hover {
    filter: brightness(0.9);
}

.react-pagination .previous:not(.disabled) a,
.react-pagination .next:not(.disabled) a {
    background: #e2f3ff;
    border: 1px solid #e2f3ff;
}

.react-pagination .disabled a {
    color: #9ab3c2;
    pointer-events: none;
}

.assignment-result-card {
    padding: 10px 25px;
    max-width: 350px;
}

.assignment-result-card .date {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #52525b;
    margin: 0 0 6px;
}

.assignment-result-card .progress-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0 0 6px;
}

.assignment-result-card .percent {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #38a6de;
    padding: 0 0 0 15px;
}

.assignment-result-card .pages {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #38a6de;
}

.assignment-result-card .progress-bar {
    height: 8px;
    background: #e2f3ff;
    border-radius: 4px;
    width: 100%;
}

.assignment-result-card .bar {
    background: #38a6de;
    border-radius: 4px;
    height: 8px;
}

.assignments-result-list {
    border-left: 1px dashed #32a3dd;
}

.assign_content .answer-item {
    border-radius: 10px;
}

.assign_content .answer-item.success-bg {
    background-color: #dcfaec;
}

.assign_content .answer-item.error-bg {
    background-color: #feeef1;
}

.success-label {
    color: #00b14b;
    font-weight: bold;
}

.complete_btn.active_btn {
    color: #fff;
    background-color: #165b95;
}

.complete_btn.disabled {
    opacity: 0.75;
    cursor: default;
    pointer-events: none;
}
